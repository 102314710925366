<template>
  <div class="common-layout">
    <el-container>
      <el-aside v-if="store.isLogged == true" width="200px">
        <el-menu class="el-menu-vertical-demo max-height">
          <router-link class="routerLink" to="/">
            <el-menu-item index="1">
              <el-icon><House /></el-icon>
              <span>Home</span>
            </el-menu-item>
          </router-link>
          <router-link class="routerLink" to="/booking">
            <el-menu-item index="2">
              <el-icon><Calendar /></el-icon>
              <span>Booking</span>
            </el-menu-item>
          </router-link>
          <router-link class="routerLink" to="/driver">
            <el-menu-item index="3">
              <el-icon><Avatar /></el-icon>
              <span>Driver</span>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/customer">
            <el-menu-item index="4">
              <el-icon><UserFilled /></el-icon>
              <span>Customer</span>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/partner">
            <el-menu-item index="5">
              <el-icon><Discount /></el-icon>
              <span>Partner </span>
            </el-menu-item>
          </router-link>

          <router-link class="routerLink" to="/contabilita">
            <el-menu-item index="6">             
              <el-icon><Management /></el-icon>
              <span>Contabilità </span>
            </el-menu-item>
          </router-link>
          
          <el-menu-item index="7" @click="logout">
            <el-icon><DocumentDelete /></el-icon>
            <span>Logout</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { isLoggedStore } from './store/logged';
export default {
  name: 'App',
  components: {},
  setup() {
    const store = isLoggedStore();
    const router = useRouter();
    const logout = () => {
      /*store.isLogged = false;
      store.nome = '';
      store.tipo = '';
      router.push('/login');*/
      store.viewMenu = false;
      store.isLogged = false;
      store.role= null;
      store.jwt=null;
      router.push('/login');
    };

    return {
      logout,
      store,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.routerLink {
  text-decoration: none;
}

.max-height {
  min-height: 100vh;
  height: 100%;
}
</style>
