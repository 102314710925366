<template>
  <h1>Driver page</h1>
  <div class="mt-4">
    <el-input v-model="input" placeholder="Inserisci nome del driver">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>

    <el-table :data="filteredTable" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
        </el-table-column>
      <el-table-column prop="name" label="nome" />
      <el-table-column prop="mail" label="mail" />
      <el-table-column label="Dettagli">
        <template #default="scope">
          <router-link :to="{ path: `/details/${scope.row.id}` }" class="routerLink">
            <el-button size="small" @click="handleClick(scope.row.id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <router-link class="routerLink" to="/addDriver">
      <el-button type="primary" @click="addDriverClick()" plain>
        Aggiungi driver</el-button>
    </router-link>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import driversAPI from "../../services/driversAPI";

export default {
  setup() {
    const drivers = ref([]);
    const value = ref(0);
    const input = ref("");
    const tableData = ref([]);

    const addValue = () => {
      value.value++;
    };
    const loadDrivers = async () => {
      try {
        const response = await driversAPI.getDrivers();

        response.data.forEach((driver) => {
          if(driver.isAlive===true)
          {let tableRow = {
            id: driver.id,
            name: (driver.name + " " + driver.surname).trim(),
            mail: driver.mail,
          };
          tableData.value.push(tableRow);
          }
        });
       
      } catch {
        console.log("error");
      }
    };

    loadDrivers();

    const filteredTable = computed(function () {
      return tableData.value.filter((row) =>
        row.name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      input,
      drivers,
      value,
      tableData,
      filteredTable,
      addValue,
    };
  },
};
</script>

<style>
</style>
