import API from './API';

export default {

  getAttivi() {
    return API().get('/booking/attiviAll');
  },
  
  getPassati() {
    return API().get('/booking/storicoAll');
  },

  getFuturi() {
    return API().get('/booking/futuriAll');
  },

  addBooking(booking) {
    return API().post('/booking/create', booking);
  },

  addTask(task){
    return API().post('/task', task)
  },

  getTrip(booking_id){
    return API().get('/booking/trip-info/'+ booking_id)
  }

}