<template>
<br> <br> <br> <br>

<el-row justify="center">


<img
   style="width: 90px; height: 116px"
          :src="require('../assets/logo.png')"
          class="image"
        />
</el-row>
<br>
<el-row  justify="center">
  <h1>BENVENUTO NELL'AREA RISERVATA SIMBA</h1> <br> <br> 

</el-row>
<br> <br> 
</template>

<script>
</script>