<template>
  <h1>COD5</h1>

  <h2>Da liquidare</h2>
  <el-input v-model="input" placeholder="Inserisci il riferimento della fattura">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="bookingDaLiquidare" show-summary style="width: 100%">
    <el-table-column prop="customer_id" label="ID cliente">
      <template #default="scope">
        <el-input :value="scope.row.customer_id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" width="100" />
    <el-table-column prop="id" label="ID Booking" width="100" >
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="date_booking" label="Data booking" width="150" />
    <el-table-column prop="imponibile" label="Imponibile" width="110" />
    <el-table-column prop="rif_fattura" label="Rif fattura" width="100" />
    <el-table-column prop="liquidare" label="Da liquidare" width="120" />
    <el-table-column prop="liquidato" label="Liquidato" width="100" />
    <el-table-column prop="esito" label="Esito" width="140" />
    <el-table-column prop="data" label="Data" width="70" />
    <el-table-column label="Dettagli">
      <template #default="scope">
      <router-link
          :to="{ path: `/contabilita/booking_plus/${headquarterID}` }"
          class="routerLink"
        >
        <el-button size="small" @click="change_liquidato(scope.row.id)">

          Liquida
        </el-button>
                </router-link>
      </template>
    </el-table-column>
  </el-table>

  <h2>Da liquidare e liquidate</h2>
  <el-input v-model="input" placeholder="Inserisci il riferimento della fattura">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="bookingPlusTable" show-summary style="width: 100%">
    <el-table-column prop="customer_id" label="ID cliente" >
      <template #default="scope">
        <el-input :value="scope.row.customer_id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" width="100" />
    <el-table-column prop="id" label="ID Booking" width="100" >
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="date_booking" label="Data booking" width="150" />
    <el-table-column prop="imponibile" label="Imponibile" width="110" />
    <el-table-column prop="rif_fattura" label="Rif fattura" width="100" />
    <el-table-column prop="liquidare" label="Da liquidare" width="120" />
    <el-table-column prop="liquidato" label="Liquidato" width="100" />
    <el-table-column prop="esito" label="Esito" width="140" />
    <el-table-column prop="data" label="Data" width="100" />
    <!-- <el-table-column prop="liquidato_check" label="Liquidato" width="140">
      <el-switch v-model="booking.liquidato" @change="change_liquidato()" />
    </el-table-column> -->
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const headquarterID = route.params.id;
    const input = ref("");
    const tableDataDaLiquidare = ref([]);
    const tableData = ref([]);
    const loadMinute = async () => {
      try {
        const response = await contabilitaAPI.getBookingHeadquarter_cod5(route.params.id);
        response.data.forEach((booking) => {
          let tableRow = {
            customer_id: booking.customer.id,
            customer_name: booking.customer.name,
            customer_surname: booking.customer.surname,
            id: booking.id,
            date_booking: booking.datePartner_gone != null ? ((booking.datePartner_gone).split('T'))[0] : null,
            imponibile: booking.imponibile + ' €',
            rif_fattura: 'Nr: ' + booking.rif_fattura,
            esito: booking.esito + "",
            data: booking.data ? ((booking.data).split('T'))[0]+"" : null+"",
            //data:"",
            liquidato_check: booking.liquidato,
          };
          if (!tableRow.liquidato_check) {
            tableRow.liquidare = ((booking.headquarter.partner.provvigione * booking.imponibile) / 100).toFixed(2);
            tableRow.liquidato = 0;
            tableDataDaLiquidare.value.push(tableRow);
          } else {
            tableRow.liquidare = 0;
            tableRow.liquidato = ((booking.headquarter.partner.provvigione * booking.imponibile) / 100).toFixed(2);
          }
          tableData.value.push(tableRow);
        });
      } catch (e) {
        console.log(e);
      }
    };

    loadMinute();

    const change_liquidato = async (id) => {
      let response = {};
      try {
        const upd = {
          "data" : new Date(),
          "liquidato": true,
          "esito": true
        };
        response = await contabilitaAPI.patchBooking(id, upd);
        return response;
      } catch(e) {
        console.log(e);
      }
    };

    const bookingDaLiquidare = computed(function () {
      return tableDataDaLiquidare.value.filter((row) =>
        row.rif_fattura.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const bookingPlusTable = computed(function () {
      return tableData.value.filter((row) =>
        row.rif_fattura.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      loadMinute,
      bookingDaLiquidare,
      bookingPlusTable,
      input,
      headquarterID,
      change_liquidato,
    };
  },
  /* methods: {
  getSummaries(param) {
      const columns = param.columns;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        const values = this.selected.map(item => Number(item[column.property]));
        console.log(values)
        if (values.length === 0) {
          sums[index] = '';
        } else if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    }
  } */
};
</script>

<style></style>
