<template>
  <h1>COD2</h1>
  <el-row :gutter="20"> 
    <el-col :span="20"   >
      <el-button style= "margin-left:110%; margin-top:-10%;" type="primary" :plain="true" @click="downloadPDF"> scarica PDF </el-button> 
    </el-col>
  </el-row> 

  <el-input v-model="input" placeholder="Inserisci il riferimento fattura">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="minuteTable" height="500" style="width: 100%">
    <el-table-column prop="customer" label="Cliente" width="200"/>
    <el-table-column prop="data" label="Data Reg" width="150"/>
    <el-table-column prop="minute" label="Minuti" width="100"/>
    <el-table-column prop="residual_minute" label="Minuti rimanenti" width="150"/>
    <el-table-column prop="imponibile" label="Imponibile" width="110"/>
    <el-table-column prop="tot_fattura" label="Tot fattura" width="110"/>
    <el-table-column prop="rif_fattura" label="Rif fattura" width="100"/>
    <el-table-column prop="esito" label="Esito" width="100" sortable/>
    <el-table-column label="Select" width="85">
      <template #default="scope">
        <el-checkbox v-model="scope.row.selected" @change="handleRowSelect(scope.row)"></el-checkbox>
      </template>
    </el-table-column>
    <el-table-column label="Dettagli">
      <template #default="scope">
        <router-link
          :to="{ path: `/contabilita/minute/details/${scope.row.id}` }"
          class="routerLink"
        >
          <el-button size="small" @click="handleClick(scope.row.id)">
            Modifica
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";
import jsPDF from "jspdf";
import { ElMessage } from "element-plus";
import "jspdf-autotable";
  const dayjs = require("dayjs");

export default {
  setup() {
    const route = useRoute();
    const input = ref("");
    const tableData = ref([]);

    const selectedRow = ref([]);
    const loadMinute = async () => {
      try {
        //const response = await contabilitaAPI.getMinuteHeadquarter(Object.assign({}, {"headquarter_id": route.params.id}));
        const response = await contabilitaAPI.getMinutePartner(Object.assign({}, {"partner_id": route.params.id}));
        
        response.data.forEach((minute) => {
          console.log(minute)
          let tableRow = {
            id: minute.id,
            data: minute.data != null ? ((minute.data).split('T'))[0] : null,
            minute: minute.minute,
            residual_minute: minute.residual_minute,
            imponibile: minute.imponibile + ' €',
            tot_fattura: minute.tot_fattura + ' €',
            rif_fattura: minute.rif_fattura,
            esito: minute.esito,
            customer: minute.customer.name + ' ' + minute.customer.surname,
          };
          tableData.value.push(tableRow);
        });
        console.log(tableData.value);
      } catch(e) {
        console.log(e);
      }
    };

    const handleRowSelect = (row) =>{
        if(row.selected){
          selectedRow.value.push(row);
        }
        console.log(selectedRow.value)
      }


    const downloadPDF = ()=>{
        //const selectedRows = fattureTableFalse.filter((row) => row.selected);
        if (selectedRow.value.length === 0) {
          ElMessage.error("Seleziona almeno una riga!");
          return;
        }
        // Call a function to generate a PDF with the selected rows
        generatePDF(selectedRow.value);
      }

      const generatePDF = (selectedRows)=> {
        const pdf = new jsPDF();
        const header = ["Cliente", "Minuti", "Imponibile", "Totale"];
        const data = selectedRows.map((row) => [
          row.customer,
          row.minute,
          row.imponibile,
          row.tot_fattura,
        ]);

        pdf.autoTable({
          head: [header],
          body: data,
        });

        const today= new Date();

        // Save the PDF or open it in a new tab
        pdf.save("dettaglio-minuti "+ dayjs(today).format("DD-MM-YY")+".pdf");
    }

    loadMinute();
    
    const minuteTable = computed(function () {
      return tableData.value.filter((row) =>
        row.rif_fattura.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      downloadPDF,
      handleRowSelect,
      loadMinute,
      minuteTable,
      input
    };
  },
};
</script>

<style></style>
