<template>
  <h1>Fatture</h1>

  <el-input v-model="input" placeholder="Inserisci il riferimento della fattura">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="fattureTable" style="width: 100%" height="500">
    <!-- <el-table-column prop="rag_soc" label="Ragione sociale" /> -->
    <el-table-column prop="id" label="id">
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="dal" label="Dal" width="200"/>
    <el-table-column prop="al" label="Al" width="200"/>
    <el-table-column prop="description" label="Descrizione" width="200"/>
    <el-table-column prop="rif" label="Rif fattura" />
    <el-table-column prop="importo" label="Importo" />
    <el-table-column prop="esito" label="Esito" width="70"/>
    <el-table-column prop="data" label="Data" width="200" sortable/>
  </el-table>
  <router-link class="routerLink" :to="{path: '/contabilita/addFattura/' + headquarterID}"> 
    <el-button class="button" type="primary" style="width: 15%">Aggiungi fattura</el-button>
  </router-link>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const headquarterID = route.params.id;
    const input = ref("");
    const tableData = ref([]);
    const loadFatture = async () => {
      try {
        const response = await contabilitaAPI.getFatture(Object.assign({}, {"headquarter_id": route.params.id}));
        console.log(response.value);
        response.data.forEach((fattura) => {
          console.log(fattura);
          let tableRow = {
            id: fattura.id,
            dal: ((fattura.date_start).split('T'))[0],
            al: ((fattura.date_end).split('T'))[0],
            description: fattura.description,
            imponibile: fattura.imponibile,
            importo: fattura.importo + ' €',
            rif: fattura.rif_fattura,
            esito: fattura.esito,
            data: ((fattura.data).split('T'))[0],
          };
          tableData.value.push(tableRow);
        });
        console.log(tableData.value);
      } catch {
        console.log("error");
      }
    };

    loadFatture();

    const fattureTable = computed(function () {
      return tableData.value.filter((row) =>
        row.rif.toString().toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return{
        loadFatture,
        fattureTable,
        input,
        headquarterID
    }
  },
};
</script>

<style>
.button {
  margin-top: 30px;
}
</style>
