<template>
  <h1>Dettagli del customer {{ customer.name }} {{ customer.surname }}</h1>

  <el-form :model="customer">
    <el-row :gutter="50" type="flex">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Id">
          <el-input v-model="customer.id" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="8" :xs="24">
        <el-form-item label="Nome">
          <el-input v-model="customer.name" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="8" :xs="24">
        <el-form-item label="Cognome">
          <el-input v-model="customer.surname" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="12">
        <el-form-item label="Data di nascita">
          <el-date-picker
            v-model="customer.date_birth"
            type="date"
            placeholder="Pick a date"
            format="DD/MM/YYYY"
            style="width: 100%"
            readonly="readonly"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12">
        <el-form-item label="Indirizzo">
          <el-input v-model="customer.address" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="15">
      <el-col :sm="8">
        <el-form-item label="Città">
          <el-input v-model="customer.city" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="CAP">
          <el-input v-model="customer.cap" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="Provincia">
          <el-input v-model="customer.area" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Mail">
          <el-input v-model="customer.mail" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="Numero di telefono">
          <el-input v-model="customer.telephone_number" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Ragione sociale">
          <el-input v-model="customer.business_name" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="P.iva">
          <el-input v-model="customer.piva" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="SDI">
          <el-input v-model="customer.sdi" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="pec">
          <el-input v-model="customer.pec" readonly="readonly" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-divider/>
    <h4> partner che fa la richeista </h4>
    <el-row :gutter="21">
      <el-col :sm="7">
        <el-form-item label="minuti">
          <el-input v-model="customer.minute" readonly="readonly" />
        </el-form-item>
      </el-col>
      <el-col :sm="15">
        <el-form-item label="data di scadenza">
          <el-date-picker v-model="customer.expiration_minute" readonly="readonly" format="DD/MM/YYYY"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="21">
      <el-col :sm="8"> 
        <el-form-item label="Partner">
          <el-input v-model="customer.partner" readonly="readonly" />
        </el-form-item>
      </el-col>
    <!--  <el-col :sm="8">
        <el-form-item label="Sede">
          <el-input v-model="customer.headquarter" readonly="readonly" />
        </el-form-item>
      </el-col> -->
    </el-row>
    <el-col :sm="6" :offset="5">
      <el-form-item>
      <router-link class="routerLink" to="/">
        <el-button type="primary" :plain="true" @click="update"> Conferma cliente</el-button> 
        </router-link>
        <router-link :to="{ path: `/customer` }" class="routerLink">
          &nbsp;
          <el-button> Annulla </el-button>
        </router-link>
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import customerAPI from "../../services/customerAPI";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    let details = ref({});

    const customer = reactive({
      id: "",
      name: "",
      surname: "",
      date_birth: Date('Y-m-d H:i:s'),
      mail: "",
      address: "",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "customer",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      esito:false,
      minute:0,
      residual_minute:0,
      expiration_minute:0,
      partner:"",
      //headquarter:""
    });


    const loadDetails = async (id) => {
      try {
        const response= await customerAPI.get_infoMinute(id);

        console.log(response.data);

        customer.id = response.data.customer.id;
        customer.name = response.data.customer.name;
        customer.surname = response.data.customer.surname;
        customer.date_birth = response.data.customer.date_birth;
        customer.mail = response.data.customer.mail;
        customer.address = response.data.customer.address;
        customer.street_number=response.data.customer.street_number;
        customer.city = response.data.customer.city;
        customer.cap = response.data.customer.cap;
        customer.area = response.data.customer.area;
        customer.telephone_number = response.data.customer.telephone_number;
        customer.business_name = response.data.customer.business_name;
        customer.piva = response.data.customer.piva;
        customer.sdi = response.data.customer.sdi;
        customer.pec = response.data.customer.pec;

        customer.esito=response.data.accepted;
        customer.minute = response.data.minute;
        customer.residual_minute = response.data.residual_minute;
        customer.expiration_minute=response.data.expiration_minute;
        customer.partner= response.data.partner.company_name;
        /*customer.headquarter= (response.data.headquarter.address 
                      + " " + response.data.headquarter.street_number
                      + ", " + response.data.headquarter.city)*/

console.log(customer.esito)
        return customer;
      } catch (e) {
        console.log(e);
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x[0];
    });

    const update = () => {
      customer.esito=true;
      updMinute().then((response) => {
        if (response.status === 200) {
          if (customer.esito == true) {
            ElMessage({
              message: "Customer attivato con successo!",
              type: "success",
            });
            console.log("Customer attivato!");
          } else {
            ElMessage.error("Customer non attivato!");
            console.log("Customer non attivato!");
          }
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const updMinute = async () => {
      let response = {};
      const esito={
        accepted:true
      }
      try {
        response = await customerAPI.upMinute(route.params.id, esito );
        console.log(response);
        return response;
      } catch {
        console.log("error");
      }
    };

    return {
      customer,
      update
    };
  },
};
</script>

<style>
.el-col {
  margin-top: 2%;
}
</style>
