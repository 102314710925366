<template>
  <h1>COD6</h1>

  <el-input v-model="input" placeholder="Inserisci l'id del booking">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="TaskTable" style="width: 100%">
    <el-table-column prop="customer_id" label="ID cliente" width="150">
      <template #default="scope">
        <el-input :value="scope.row.customer_id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="customer_name" label="Nome" width="150" />
    <el-table-column prop="customer_surname" label="Cognome" width="150" />
    <el-table-column prop="id" label="ID Booking" width="250">
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
      </el-table-column>

    <el-table-column prop="task" class-name="sub_row" label="Task di ogni booking">
      <template #default="scope">
        <el-table :data="scope.row.task" :show-header="true" border style="width: 100%">
          <el-table-column prop="description" width="350" label="Descrizione" />
          <el-table-column prop="cost" label="Costo" />
        </el-table>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const input = ref("");
    const tableData = ref([]);
    const loadTask = async () => {
      try {
        const response = await contabilitaAPI.getBookingHeadquarter_cod6(route.params.id);
        response.data.forEach((booking) => {
          let tableRow = {
            customer_id: booking.customer.id,
            customer_name: booking.customer.name,
            customer_surname: booking.customer.surname,
            id: booking.id,
            task: booking.tasks,
          };
          tableRow.task.forEach((task)=>{
            if(task.pay===true){
                tableData.value.push(tableRow);
            }
          })
        });

      } catch (e) {
        console.log(e);
      }
    };
    console.log(tableData.value);
    loadTask();

    const TaskTable = computed(function () {
      return tableData.value.filter((row) =>
        row.id.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      loadTask,
      input,
      tableData,
      TaskTable,
    };
  },
};
</script>

<style>
</style>
