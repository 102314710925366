<template>
  <h1>COD7</h1>

  <h2>Booking svolti come driver</h2>
  <el-input v-model="input" placeholder="Inserisci l'id">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="bookingTable" height="500" style="width: 100%">
    <el-table-column prop="id" label="ID" >
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
      </el-table-column>
    <el-table-column prop="data" label="data"  width="150" />
    <el-table-column prop="customer" label="cliente" width="150"/>
    <el-table-column prop="partner" label="partner" width="100"/>
    <el-table-column prop="partner_point" label="sede"  width="200"/>
    <el-table-column prop="cost" label="guadagno"  width="100"/>
    <el-table-column prop="km" label="km"  width="100"/>
  </el-table>

  <h2>Booking svolti come jolly</h2>
  <el-input v-model="input" placeholder="Inserisci l'id">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="tripTable" style="width: 100%">
    <el-table-column prop="id" label="ID">
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
      </el-table-column>
    <el-table-column prop="data" label="data"  width="200" />
    <el-table-column prop="isgone" label="tipo di trip"  width="200" />
    <el-table-column prop="cost" label="guadagno"  width="100"/>
    <el-table-column prop="km" label="km"  width="100"/>
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";
const dayjs = require('dayjs');
export default {
  setup() {
    const route = useRoute();
    const input = ref("");
    const tableData_booking = ref([]);
    const tableData_trip = ref([]);
    
    const loadBooking = async () => {
      try {
        const response = await contabilitaAPI.getDetailsRemuneration_booking(route.params.id);

        response.data.forEach((booking) => {
          if(booking.gone_driver.id===route.params.id){
              let tableRow = {
                id:booking.id,
                data:dayjs(booking.datePartner_gone).format('YYYY-MM-DD'),
                customer: (booking.customer.name + " " + booking.customer.surname).trim(),
                partner: booking.headquarter.partner.company_name,
                partner_point:(
                    booking.headquarter.address +
                    ' ' +
                    booking.headquarter.street_number +
                    ' ' +
                    booking.headquarter.city +
                    ' ' +
                    booking.headquarter.cap +
                    ' ' +
                    booking.headquarter.province
                ).trim(),
                cost: (booking.simba_minuteGone)*0.32 + " €",
                km: booking.simba_kmGone + " km"

              };
              tableData_booking.value.push(tableRow);
            }
        
        if(booking.return_driver.id===route.params.id){
          let tableRow = {
                id:booking.id,
                data:dayjs(booking.datePartner_gone).format('YYYY-MM-DD'),
                customer: (booking.customer.name + " " + booking.customer.surname).trim(),
                partner: booking.headquarter.partner.company_name,
                partner_point:(
                    booking.headquarter.address +
                    ' ' +
                    booking.headquarter.street_number +
                    ' ' +
                    booking.headquarter.city +
                    ' ' +
                    booking.headquarter.cap +
                    ' ' +
                    booking.headquarter.province
                ).trim(),
                cost: (booking.simba_minuteReturn)*0.32  + " €",
                km: booking.simba_kmReturn + " km"

          };
              tableData_booking.value.push(tableRow);
            }

         /*if(booking.gone_driver.id===route.params.id && booking.return_driver.id===route.params.id){
          let tableRow = {
                id:booking.id,
                data:dayjs(booking.dateBooking).format('YYYY-MM-DD'),
                customer: (booking.customer.name + " " + booking.customer.surname).trim(),
                partner: booking.headquarter.partner.company_name,
                partner_point:(
                    booking.headquarter.address +
                    ' ' +
                    booking.headquarter.street_number +
                    ' ' +
                    booking.headquarter.city +
                    ' ' +
                    booking.headquarter.cap +
                    ' ' +
                    booking.headquarter.province
                ).trim(),
                cost:((booking.simba_minuteReturn + booking.simba_minuteGone)*0.32).toFixed(2)  + " €" ,
                km: (booking.simba_kmGone + booking.simba_kmReturn) + " km"

          };
              tableData_booking.value.push(tableRow);
            }*/

        
        })
      } catch (error){
        console.log(error);
      }
    };

    const loadTrip = async () => {
      try {
        const response = await contabilitaAPI.getDetailsRemuneration_trip(route.params.id);
        console.log(response.data);
        response.data.forEach((trip) => {
          let tableRow = {
            id:trip.id,
            data: trip.dateJolly_gone!=null? dayjs(trip.dateJolly_gone).format('YYYY-MM-DD'): dayjs(trip.dateJolly_return).format('YYYY-MM-DD'),
            cost: ((trip.duration)*0.35).toFixed(2) + " €" ,
            isgone: trip.gone === true? "andata":"ritorno",
            km: (trip.km) + " km"
          };
          tableData_trip.value.push(tableRow);
        });
      } catch (error){
        console.log(error);
      }
    };

    loadBooking();
    loadTrip();
    
    const bookingTable = computed(function () {
      return tableData_booking.value.filter((row) =>
        row.id.toLowerCase().includes(input.value.toLowerCase())
      );
    });
    const tripTable = computed(function () {
      return tableData_trip.value.filter((row) =>
        row.id.toLowerCase().includes(input.value.toLowerCase())
      );
    });
    return {
      loadTrip,
      loadBooking,
      bookingTable,
      tripTable,
      input
    };
  },
};
</script>

<style></style>
