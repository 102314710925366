import API from './API';

export default {
    
    getHeadquarters() {
        return API().get('/headquarter');
      },

    getFatture(id) {
      return API().post('/headquarter/fatturaHeadquarter', id);
    },

    getDetailsRemuneration_booking(id) {
      return API().get('/driver/storico/booking/' + id);
    },

    getDetailsRemuneration_trip(id) {
      return API().get('/driver/storico/trip/' + id);
    },
    
    change_to_liquidato(id, liquidato) {
      return API().patch('/remuneration/' + id, liquidato);
    },

  getHeadquarter(id) {
    //console.log(id);
    return API().get('/headquarter/'+id, id);
  },

  getMinuteInfo(data){
    return API().post('/minute/minute-info',data)
  },

  //minutePartner
  getMinutePartner(id) {
    return API().post('/minute/minutePartner', id);
  },

  getMinute(id) {
    return API().get('/minute/' + id);
  },

  patchMinute(minute) {
    return API().patch('/minute/' + minute.id, minute);
  },

  addFattura(fattura) {
    return API().post('/fattura', fattura);
  },

  getBookingHeadquarter_cod3(id) {
    return API().get('/headquarter/booking/cod3/' + id);
  },

  getBookingHeadquarter_cod4(id) {
    return API().get('/headquarter/booking/cod4/' + id);
  },

  getBookingHeadquarter_cod5(id) {
    return API().get('/headquarter/booking/cod5/' + id);
  },

  getBookingHeadquarter_cod6(id) {
    return API().get('/headquarter/booking/cod6/' + id);
  },

  patchBooking(id, booking) {
    return API().patch('/booking/' + id, booking);
  }


}