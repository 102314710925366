import API from './API';

export default {

getCustomers() {
  return API().get('/customer');
},

getCustomer_all() {
  return API().get('/customer/getAll');
},
  
getCustomer(id) {
  return API().get('/customer/'+id);
},

addCustomer(customer) {
  return API().post('/register', customer);
},

updCustomer(customer) {
  return API().patch('/customer/' + customer.id, customer)
},

//minute 
updMinute(minute) {
  return API().patch('/minute/' + minute.id, minute)
},

addMinute(minute){
  return API().post('/minute', minute)
},

getMinute(){
  return API().get('/minute/minuteAll')
},

getMinute_by_customer(customer){
  return API().post('minute/minuteCustomer', customer)
},

get_infoMinute(id){
  return API().get('minute/minute_info/'+id)
},

upMinute(id, esito) {
  return API().patch('/minute/' + id, esito)
},
  

}