import API from './API';

export default {

getDrivers() {
    return API().get('/driver');
  },


getAreas() {
    return API().get('/area');
  },

getRemuneration_liquidati(){
  return API().get('remuneration/all')
}

}