<template>
  <h1>Booking</h1>
  <div class="mt-4">
    <router-link class="routerLink" to="/addBooking">
      <el-button type="primary" style="width: 20%" @click="onAddItem" plain
        >Aggiungi booking</el-button
      >
    </router-link>
    <h2>Attivi</h2>
    <el-input v-model="input" placeholder="Inserisci id del booking">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>

    <el-table :data="filteredTableAttivi" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id"  width="70">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="driver_gone" label="driver andata" />
      <el-table-column prop="driver_return" label="driver ritorno" />
      <el-table-column prop="jolly_gone" label="jolly andata" />
      <el-table-column prop="jolly_return" label="jolly ritorno" />
      <el-table-column prop="pick_point" label="pick point" />
      <el-table-column prop="partner_point" label="partner point" />
      <el-table-column prop="leave_point" label="leave point" />
      <el-table-column prop="state" label="stato" width="100"/>
        <!--<el-table-column prop="payment_simba" label="Costo simba" />
      <el-table-column prop="payment_task" label="Costo lavori" />-->
      <el-table-column prop="andata" label="Arrivo auto" width="70"/>
      <el-table-column prop="ritorno" label="Ritiro auto" width="70"/>
      <el-table-column prop="pick_point_date" label="A driver" width="70"/>
      <el-table-column prop="pick_point_date" label="A jolly" width="70"/>
      <el-table-column prop="ritorno" label="R driver" width="70"/>
      <el-table-column prop="leave_point_date" label="R jolly" width="70"/>
    </el-table>

    <h2>Passati</h2>
    <el-input v-model="input" placeholder="Inserisci id del booking">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="filteredTablePassati" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id" width="60">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="driver_gone" label="driver andata" />
      <el-table-column prop="driver_return" label="driver ritorno" />
      <el-table-column prop="jolly_gone" label="jolly andata" />
      <el-table-column prop="jolly_return" label="jolly ritorno" />
      <el-table-column prop="pick_point" label="pick point" />
      <el-table-column prop="partner_point" label="partner point" />
      <el-table-column prop="leave_point" label="leave point" />
      <el-table-column prop="state" label="stato" />
      <!--<el-table-column prop="payment_simba" label="Costo simba" />
      <el-table-column prop="payment_task" label="Costo lavori" />-->
      <el-table-column prop="andata" label="Arrivo auto" width="70"/>
      <el-table-column prop="ritorno" label="Ritiro auto" width="70"/>
      <el-table-column prop="pick_point_date" label="A driver" width="70"/>
      <el-table-column prop="pick_point_date" label="A jolly" width="70"/>
      <el-table-column prop="ritorno" label="R driver" width="70"/>
      <el-table-column prop="leave_point_date" label="R jolly" width="70"/>
    </el-table>

    <h2>Futuri</h2>
    <el-input v-model="input" placeholder="Inserisci id del booking">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="filteredTableFuturi" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id" width="60">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
      </el-table-column>
      <el-table-column prop="driver_gone" label="driver andata" />
      <el-table-column prop="driver_return" label="driver ritorno" />
      <el-table-column prop="jolly_gone" label="jolly andata" />
      <el-table-column prop="jolly_return" label="jolly ritorno" />
      <el-table-column prop="pick_point" label="pick point" />
      <el-table-column prop="partner_point" label="partner point" />
      <el-table-column prop="leave_point" label="leave point" />
      <el-table-column prop="state" label="stato" width="150"/>
      <!--<el-table-column prop="payment_simba" label="Costo simba" />
      <el-table-column prop="payment_task" label="Costo lavori" />-->
      <el-table-column prop="andata" label="Arrivo auto" width="60"/>
      <el-table-column prop="ritorno" label="Ritiro auto" width="60"/>
      <el-table-column prop="pick_point_date" label="A driver" width="60"/>
      <el-table-column prop="pick_point_date" label="A jolly" width="60"/>
      <el-table-column prop="ritorno" label="R driver" width="60"/>
      <el-table-column prop="leave_point_date" label="R jolly" width="60"/>
    </el-table>
    <router-link class="routerLink" to="/addBooking">
      <el-button class="button" type="primary" style="width: 50%" @click="onAddItem" plain
        >Aggiungi booking</el-button
      >
    </router-link>
  </div>
</template>

<script>
import { ref, computed,} from "vue";
import bookingAPI from "../../services/bookingAPI.js";

const dayjs = require("dayjs");
export default {
  setup() {
    const input = ref("");
    const tableDataAttivi = ref([]);
    const tableDataPassati = ref([]);
    const tableDataFuturi = ref([]);

    const loadAttivi = async () => {
      try {
        const response = await bookingAPI.getAttivi();

      for (let booking of response.data )
        {    //response.data.forEach((booking) => {
          let tableRow = {
            id: booking.booking_id,
            partner: booking.partner,
            driver_gone: (
              booking.driver_gone_name +
              " " +
              booking.driver_gone_surname
            ).trim(),
            driver_return: (
              booking.driver_return_name +
              " " +
              booking.driver_return_surname
            ).trim(),
            auto: (
              booking.car.marca +
              " " +
              booking.car.modello +
              " " +
              booking.car.targa
            ).trim(),
            pick_point: (
              booking.pick_point.address +
              " " +
              booking.pick_point.street_number +
              " " +
              booking.pick_point.city +
              " " +
              booking.pick_point.cap +
              " " +
              booking.pick_point.province
            ).trim(),

            partner_point: (
              booking.partner_point.address +
              " " +
              booking.partner_point.street_number +
              " " +
              booking.partner_point.city +
              " " +
              booking.partner_point.cap +
              " " +
              booking.partner_point.province
            ).trim(),
            leave_point: (
              booking.leave_point.address +
              " " +
              booking.leave_point.street_number +
              " " +
              booking.leave_point.city +
              " " +
              booking.leave_point.cap +
              " " +
              booking.leave_point.province
            ).trim(),
            km: booking.km + "km",
            state: state(booking.state),
            payment_simba: booking.payment_simba + " €",
            payment_task: booking.payment_task + " €",
            andata: dayjs(booking.consegna).format("DD/MM HH:mm"),
            ritorno: dayjs(booking.ritiro).format("DD/MM HH:mm"),
            leave_point_date: booking.leave_point_date!=null ? dayjs(booking.leave_point_date).format("DD/MM HH:mm") : "-",
            pick_point_date:booking.pick_point_date!=null ?  dayjs(booking.pick_point_date).format("DD/MM HH:mm"): "-",
            jolly_gone: (await loadTrip(booking.booking_id)).jolly_gone,
            jolly_return: (await loadTrip(booking.booking_id)).jolly_return
          };
          tableDataAttivi.value.push(tableRow);
          console.log(tableRow.jolly_gone)
         
        }//);
      } catch {
        console.log("error");
      }
    };

    const loadTrip = async (booking_id) =>{
      let trip={
        jolly_return:"",
        jolly_gone:""
      }
      const res=(await bookingAPI.getTrip(booking_id)).data[0];

      //console.log("goneee ", res.tripGone)
      if(res.tripGone!=null ){
        if(res.tripGone.jolly!= null && res.tripGone.jolly!= null)
       {
        //console.log(res.tripGone.jolly.name)
          trip.jolly_gone= res.tripGone.jolly.name+ " "+ res.tripGone.jolly.surname
          //console.log(trip.jolly_gone)
        }
      }else{
        trip.jolly_gone="-"
      }

      if(res.tripReturn!=null)
      if(  res.tripReturn.jolly!= null && res.tripReturn.jolly!= null){
        trip.jolly_return= res.tripReturn.jolly.name+ " "+ res.tripReturn.jolly.surname
      }else{
        trip.jolly_return="-"
      }
      //trip.jolly_gone= res.tripGone!=null ?  res.tripGone.jolly.name+ " "+ res.tripGone.jolly.surname : "-"
      //trip.jolly_return= res.tripReturn!=null ?  res.tripReturn.jolly.name+ " "+ res.tripReturn.jolly.surname : "-"
      return trip;
    }

    const loadPassati = async () => {
      try {
        const response = await bookingAPI.getPassati();

        for (let booking of response.data )
        {  
          let tableRow = {
            id: booking.booking_id,
            partner: booking.partner,
            driver_gone: (
              booking.driver_gone_name +
              " " +
              booking.driver_gone_surname
            ).trim(),
            driver_return: (
              booking.driver_return_name +
              " " +
              booking.driver_return_surname
            ).trim(),
            auto: (
              booking.car.marca +
              " " +
              booking.car.modello +
              " " +
              booking.car.targa
            ).trim(),
            pick_point: (
              booking.pick_point.address +
              " " +
              booking.pick_point.street_number +
              " " +
              booking.pick_point.city +
              " " +
              booking.pick_point.cap +
              " " +
              booking.pick_point.province
            ).trim(),

            partner_point: (
              booking.partner_point.address +
              " " +
              booking.partner_point.street_number +
              " " +
              booking.partner_point.city +
              " " +
              booking.partner_point.cap +
              " " +
              booking.partner_point.province
            ).trim(),
            leave_point: (
              booking.leave_point.address +
              " " +
              booking.leave_point.street_number +
              " " +
              booking.leave_point.city +
              " " +
              booking.leave_point.cap +
              " " +
              booking.leave_point.province
            ).trim(),
            km: booking.km,
            payment_simba: booking.payment_simba + " €",
            payment_task: booking.payment_task + " €",
            state: state(booking.state),
            andata: dayjs(booking.consegna).format("DD/MM HH:mm"),
            ritorno: dayjs(booking.ritiro).format("DD/MM HH:mm"),
            leave_point_date: booking.leave_point_date!=null ? dayjs(booking.leave_point_date).format("DD/MM HH:mm") : "-",
            pick_point_date:booking.pick_point_date!=null ?  dayjs(booking.pick_point_date).format("DD/MM HH:mm"): "-",
            jolly_gone: (await loadTrip(booking.booking_id)).jolly_gone ,
            jolly_return: (await loadTrip(booking.booking_id)).jolly_return 
          };
          tableDataPassati.value.push(tableRow);
        }
      } catch {
        console.log("error");
      }
    };

    const loadFuturi = async () => {
      try {
        const response = await bookingAPI.getFuturi();

       for (let booking of response.data )
        {  

          //console.log(response.data)
          let tableRow = {
            id: booking.booking_id,
            partner: booking.partner,
            driver_gone: (
              booking.driver_gone_name +
              " " +
              booking.driver_gone_surname
            ).trim(),
            driver_return: (
              booking.driver_return_name +
              " " +
              booking.driver_return_surname
            ).trim(),
            auto: (
              booking.car.marca +
              " " +
              booking.car.modello +
              " " +
              booking.car.targa
            ).trim(),
            pick_point: (
              booking.pick_point.address +
              ", " +
              booking.pick_point.street_number +
              " " +
              booking.pick_point.city +
              " " +
              booking.pick_point.cap +
              " " +
              booking.pick_point.province
            ).trim(),

            partner_point: (
              booking.partner_point.address +
              ", " +
              booking.partner_point.street_number +
              " " +
              booking.partner_point.city +
              " " +
              booking.partner_point.cap +
              " " +
              booking.partner_point.province
            ).trim(),
            leave_point: (
              booking.leave_point.address +
              ", " +
              booking.leave_point.street_number +
              " " +
              booking.leave_point.city +
              " " +
              booking.leave_point.cap +
              " " +
              booking.leave_point.province
            ).trim(),
            km: booking.km,
            state: state(booking.state),
            payment_simba: booking.payment_simba,
            payment_task: booking.payment_task,
            andata: dayjs(booking.consegna).format("DD/MM HH:mm"),
            ritorno: dayjs(booking.ritiro).format("DD/MM HH:mm"),
            leave_point_date: booking.leave_point_date!=null ? dayjs(booking.leave_point_date).format("DD/MM HH:mm") : "-",
            pick_point_date:booking.pick_point_date!=null ?  dayjs(booking.pick_point_date).format("DD/MM HH:mm"): "-",
            jolly_gone: (await loadTrip(booking.booking_id)).jolly_gone != null  ? (await loadTrip(booking.booking_id)).jolly_gone : "-",
            jolly_return: (await loadTrip(booking.booking_id)).jolly_return != null ? (await loadTrip(booking.booking_id)).jolly_return : "-" 

          };

          
          tableDataFuturi.value.push(tableRow);
        }
      } catch(e) {
        console.log(e);
      }
    };

    const state = (state)=>{
      switch (state) {
      case 'before':
        state= "Non ancora iniziato"
      break;
      case 'jolly_driver_atPartner1':
        state="Jolly e driver presso la sede";
      break;
      case 'driver_atCustomer1' :
      state='Driver arrivato dal cliente';
      break;
      case 'driver_scanner_customer1':
        state="Il cliente ha consegnato l'auto"
        break;
      case 'take_picture_atCustomer1':
         state="Il driver ha fatto le foto"
        break;
      case 'driver_atPartner1':
         state="auto consegnata in officina"
        break;
      case 'driver_atPartner2':
         state="auto ritirata dall'officina"
      break;
      case 'driver_atCustomer2':
         state="Driver arrivato dal cliente"
      break;
      case 'driver_scanner_customer2':
        state="Il cliente ha riconsegnato l'auto"
        break;
      case 'take_picture_atCustomer2':
         state="Il driver ha fatto le foto"
        break;
      case 'jolly_atCustomer2':
         state="Concluso"
        break;
        }
return state;
    }

    loadAttivi();
    loadPassati();
    loadFuturi();

    const filteredTableAttivi = computed(function () {
      return tableDataAttivi.value.filter((row) =>
        row.id.includes(input.value.toLowerCase())
      );
    });
    const filteredTablePassati = computed(function () {
      return tableDataPassati.value.filter((row) =>
        row.id.includes(input.value.toLowerCase())
      );
    });
    const filteredTableFuturi = computed(function () {
      return tableDataFuturi.value.filter((row) =>
        row.id.includes(input.value.toLowerCase())
      );
    });

    return {
      input,
      tableDataAttivi,
      tableDataPassati,
      tableDataFuturi,
      filteredTableAttivi,
      filteredTablePassati,
      filteredTableFuturi,
      loadAttivi,
      loadPassati,
      loadFuturi,
    };
  },
};
</script>

<style>
/*.mytable tr td{
  border: 1px solid black;
}*/
</style>
