<template>

  <h1>CLIENTI</h1> <br> 
 Clicca quì per registrare un nuovo cliente
  <router-link class="routerLink" to="/addCustomer">
    <el-icon style="vertical-align: middle" color="#409EFC" :size="20" @click="addDriverClick()"
      ><CirclePlus/></el-icon>
  </router-link>
  
<br><br><br><br>
  <h2>Clienti in attesa di conferma</h2>
  <div class="top">
    <el-input v-model="input" placeholder="Inserisci il nome del customer">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="filteredTableNotAlive" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id cliente">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
        </el-table-column>
      <el-table-column prop="name" label="nome" />
      <el-table-column prop="min_id" label="id minuti" >
        <template #default="scope">
          <el-input :value="scope.row.min_id" />
        </template>
        </el-table-column>
      <el-table-column prop="mail" label="mail" />
      <el-table-column prop="accepted" label="accettato" />
      <el-table-column label="dettagli">
        <template #default="scope">
          <router-link
            :to="{ path: `/details/customerNotAlive/${scope.row.min_id}` }"
            class="routerLink"
          >
            <el-button size="small" @click="handleClick(scope.row.min_id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <br><br><br><br>
  
  <h2>Tutti i clienti</h2>
  <div class="top">
    <el-input v-model="input2" placeholder="Inserisci il nome del customer">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="filteredTableAlive" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id cliente">
        <template #default="scope">
          <el-input :value="scope.row.id" />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="nome" />
      <el-table-column prop="mail" label="mail" />
      <el-table-column label="dettagli">
        <template #default="scope">
          <router-link :to="{ path: `/details/customerAlive/${scope.row.id}` }" class="routerLink">
            <el-button size="small" @click="handleClick(scope.row.id)">
              Visualizza
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import customerAPI from "../../services/customerAPI";

export default {
  setup() {
    const value = ref(0);
    const input = ref("");
    const input2 = ref("");
    const tableDataAlive = ref([]);
    const tableDataNotAlive = ref([]);

    const loadCustomers = async () => {
      try {
        const response= await customerAPI.getCustomer_all();
        console.log(response.data)

        response.data.forEach((customer_detail) => {
           if (customer_detail.role=='customer') 
            {if (customer_detail.minutes.length != 0 && customer_detail.minutes[0].accepted === false) {
              let tableRow = {
                id: customer_detail.id,
                name: customer_detail.name + ' ' + customer_detail.surname,
                mail: customer_detail.mail,
                min_id: customer_detail.minutes[0].id,
                accepted: customer_detail.minutes[0].accepted
              };
              tableDataNotAlive.value.push(tableRow);
            } else {
              let tableRowAlive = {
                id: customer_detail.id,
                name: customer_detail.name + ' ' + customer_detail.surname,
                mail: customer_detail.mail,
                accepted: 'false'
              };
              tableDataAlive.value.push(tableRowAlive);
            } }
      
        })
      } catch (e) {
        console.log(e);
      }
    };

    loadCustomers();

    const filteredTableNotAlive = computed(function () {
      return tableDataNotAlive.value.filter((row) =>
        row.name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const filteredTableAlive = computed(function () {
      return tableDataAlive.value.filter((row) =>
        row.name.toLowerCase().includes(input2.value.toLowerCase())
      );
    });

    return {
      input,
      input2,
      value,
      tableDataAlive,
      tableDataNotAlive,
      filteredTableAlive,
      filteredTableNotAlive,
    };
  },
};
</script>

<style>
.top {
  margin-top: 30px;
}
</style>
