<template>
  <h1>Dettagli</h1>

  <el-form :model="minute">
    <el-row :gutter="50" type="flex">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Id">
          <el-input v-model="minute.id" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="8" :xs="24">
        <el-form-item label="Minuti">
          <el-input v-model="minute.minute" readonly="readonly"/>
        </el-form-item>
      </el-col>

      <el-col :sm="8" :xs="24">
        <el-form-item label="Minuti residui">
          <el-input v-model="minute.residual_minute" readonly="readonly"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="12">
        <el-form-item label="Scadenza">
          <el-date-picker
            v-model="minute.expiration_minute"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
            readonly="readonly"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="12">
        <el-form-item label="Imponibile">
          <el-input v-model="minute.imponibile" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="15">
      <el-col :sm="8">
        <el-form-item label="Rif fattura">
          <el-input v-model="minute.rif_fattura" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="9">
        <el-form-item label="TOT">
          <el-input v-model="minute.tot_fattura" />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :offset="1" :xs="24">
        <el-form-item label="Esito">
          <el-switch v-model="minute.esito" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
    <el-col :sm="12">
        <el-form-item label="Data">
          <el-date-picker
            v-model="minute.data"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :offset="15">
        <el-form-item>
          <router-link class="routerLink" to="/">
            <el-button type="primary" @click="update"> Salva </el-button>
          </router-link> 
          <router-link :to="{ path: `/contabilita` }" class="routerLink">
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { ref, toRaw } from "vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    let details = ref({});
    const minute = reactive({
      id: "",
      minute: "",
      residual_minute: "",
      expiration_minute: "",
      imponibile: "",
      tot_fattura: "",
      rif_fattura: "",
      esito: "",
      data: "",
    });

    const loadDetails = async (id) => {
      try {
        const response = await contabilitaAPI.getMinute(id);
        minute.id = response.data.id;
        minute.minute = response.data.minute;
        minute.residual_minute = response.data.residual_minute;
        minute.expiration_minute = response.data.expiration_minute;
        minute.imponibile = response.data.imponibile;
        minute.tot_fattura = response.data.tot_fattura;
        minute.rif_fattura = response.data.rif_fattura;
        minute.esito = response.data.esito;
        minute.data = response.data.data;

        return minute;
      } catch (e) {
        console.log(e);
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x[0];
    });

    const update = () => {
      updMinute(toRaw(minute)).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: "Campo aggiornato con successo!",
            type: "success",
          });
          console.log("Campo aggiornato!");
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const updMinute = async (minute) => {
      let response = {};
      try {
        response = await contabilitaAPI.patchMinute(minute);
        console.log(response);
        return response;
      } catch {
        console.log("error");
      }
    };

    return {
      minute,
      update,
    };
  },
};
</script>

<style>
.el-col {
  margin-top: 2%;
}
</style>
