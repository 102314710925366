import API from './API';

export default {

  getHeadquarters() {
      return API().get('/headquarter/headquarterAll');
  },

  getHeadquarter(id) {
    return API().get('/headquarter/' + id);
  },

  postHeadquarter(headquarter) {
    return API().post('/headquarter', headquarter);
  },

  getHeadquarters_toPartner() {
      return API().get('/partner/headquarter');
  },

  getPartners() {
    return API().get('/partner');
  },

  get_specificPartner(id){
    return API().get('/partner/'+id);
  },

  postPartner(partner) {
    return API().post('/partner', partner);
  },

  getEmployees(){
    return API().get('/employee/employeeAll')
  },

  postEmployee(employee){
    return API().post('/register', employee)
  },

  getHeadquarters_byPartner(partner_id){
    return API().get('/headquarter/partner/'+partner_id)
  },

  getEmployees_byHeadquarter(headquarter_id){
    return API().get('/employee/'+headquarter_id)
  }
}