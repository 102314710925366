<template>
  <h1>COD3</h1>

  <el-input v-model="input" placeholder="Inserisci il cognome del cliente">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="fattureTable" style="width: 100%">
    <el-table-column prop="customer_id" label="ID cliente">
      <template #default="scope">
        <el-input :value="scope.row.customer_id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="customer_name" label="Nome" />
    <el-table-column prop="customer_surname" label="Cognome" width="200" sortable/>
    <el-table-column prop="id" label="ID Booking" width="150" >
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="date_booking" label="Data booking" width="150" sortable/>
    <el-table-column prop="imponibile" label="Imponibile" width="110" />
    <el-table-column prop="tot_fattura" label="Tot fattura" width="110" />
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import contabilitaAPI from "../../../services/contabilitaAPI";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const input = ref("");
    const tableData = ref([]);
    const loadMinute = async () => {
      try {
        const response = await contabilitaAPI.getBookingHeadquarter_cod3(route.params.id);
       console.log(response.data)
        response.data.forEach((booking) => {
          let tableRow = {
            customer_id: booking.customer.id,
            customer_name: booking.customer.name,
            customer_surname: booking.customer.surname,
            id: booking.id,
            date_booking: booking.datePartner_gone != null ? ((booking.datePartner_gone).split('T'))[0] : null,
            imponibile: booking.imponibile + ' €',
            tot_fattura: booking.tot_fattura + ' €',
          };
          tableData.value.push(tableRow);
        });
      } catch {
        console.log("error");
      }
    };

    loadMinute();

    const fattureTable = computed(function () {
      return tableData.value.filter((row) =>
        row.customer_surname.toLowerCase().includes(input.value.toLowerCase())
      );
    });


    return {
      loadMinute,
      fattureTable,
      input,
    };
  },
};
</script>

<style></style>
