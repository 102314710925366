import { createRouter, createWebHistory } from 'vue-router';

import LoginPage from '../Views/LoginPage.vue';

import DriverDetailPage from '../Views/driver/DriverDetailPage.vue';
import DriverPage from '../Views/driver/DriverPage.vue';
import AddDriverPage from '../Views/driver/AddDriverPage.vue'
import BookingPage from '../Views/booking/BookingPage.vue';
import FirstPage from '../Views/FirstPage.vue';
import AddBookingPage from '../Views/booking/AddBookingPage.vue'
import CustomerPage from '../Views/customer/CustomerPage.vue';
import AddCustomerPage from '../Views/customer/AddCustomerPage.vue';
import PartnerPage from '../Views/partner/PartnerPage.vue';
import AddPartnerPage from '../Views/partner/AddPartnerPage.vue';
import CodePage from '../Views/contabilita/CodePage.vue';
import FatturePage from '../Views/contabilita/COD1/FatturePage.vue';
import AddFatturaPage from '../Views/contabilita/COD1/AddFatturaPage.vue';
import MinutePage from '../Views/contabilita/COD2/MinutePage.vue';
import DetailsMinutePage from '../Views/contabilita/COD2/DetailsMinutePage.vue';
import BookingCostPositivePage from '../Views/contabilita/COD3/BookingCostPositivePage.vue';
import BookingPayFalsePage from '../Views/contabilita/COD4/BookingPayFalsePage.vue';
import AddBookingPayFalsePage from '../Views/contabilita/COD4/AddBookingPayFalsePage.vue';
import BookingPlusPage from '../Views/contabilita/COD5/BookingPlusPage.vue';
import TaskPage from '../Views/contabilita/COD6/TaskPage.vue';
import CustomerAliveDetailPage from '../Views/customer/CustomerAliveDetailPage.vue';
import CustomerNotAliveDetailPage from '../Views/customer/CustomerNotAliveDetailPage.vue';
import DriverRemunerationPage from '../Views/contabilita/COD7/DriverRemunerationPage.vue';
import DetailRemunerationPage from '../Views/contabilita/COD7/DetailRemunerationPage.vue';
import AddHeadquarterPage from '../Views/partner/AddHeadquarterPage.vue';
import AddEmployeePage from '../Views/partner/AddEmployeePage.vue';

//import SearchPage from '../Views/SearchPage.vue';
import { isLoggedStore } from '@/store/logged';
const routes = [
  {
    path: '/',
    component: FirstPage,
  },
  {
    path: '/driver',
    component: DriverPage,
  },
  {
    path: '/addDriver',
    component: AddDriverPage,
  },
  {
    path: '/booking',
    component: BookingPage,
  },
  {
    path: '/addBooking',
    component: AddBookingPage,
  },
  {
    path: '/customer',
    component: CustomerPage,
  },
  {
    path: '/addCustomer',
    component: AddCustomerPage,
  },
  {
    path: '/partner',
    component: PartnerPage,
  },
  {
    path: '/addPartner',
    component: AddPartnerPage,
  },
  {
    path: '/headquarter/:id',
    component: AddHeadquarterPage,
  },
  {
    path: '/employee/:id',
    component: AddEmployeePage,
  },
  {
    path: '/contabilita',
    component: CodePage,
  },
  {
    path: '/contabilita/fatture/:id',
    component: FatturePage,
  },
  {
    path: '/contabilita/addFattura/:id',
    component: AddFatturaPage,
  },
  {
    path: '/contabilita/minute/:id',
    component: MinutePage,
  },
  {
    path: '/contabilita/minute/details/:id',
    component: DetailsMinutePage,
  },
  {
    path: '/contabilita/booking_cost_positive/:id',
    component: BookingCostPositivePage,
  },
  {
    path: '/contabilita/booking_pay_false/:id',
    component: BookingPayFalsePage,
  },
  {
    path: '/contabilita/add_booking_pay_false/:headquarter_id/:booking_id',
    component: AddBookingPayFalsePage,
  },
  {
    path: '/contabilita/booking_plus/:id',
    component: BookingPlusPage,
  },
  {
    path: '/contabilita/task/:id',
    component: TaskPage,
  },
  {
    path: '/details/customerAlive/:id',
    component: CustomerAliveDetailPage,
  },
  {
    path: '/details/customerNotAlive/:id',
    component: CustomerNotAliveDetailPage,
  },
  {
    path: '/contabilita/remuneration',
    component: DriverRemunerationPage,
  },
  {
    path: '/contabilita/remuneration/:id',
    component: DetailRemunerationPage,
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/details/:id',
    component: DriverDetailPage,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to) => {
  const store = isLoggedStore();
  if (
    // make sure the user is authenticated
    store.isLogged == false &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'Login'
  ) {
    // redirect the user to the login page
    return { name: 'Login' };
  }
});

export default router;
