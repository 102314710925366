<template>
  <h1>Nuovo Driver</h1>
  <el-form ref="form" :model="driver"  :rules="formRule"  >
    <el-row :gutter="50">
      <el-col :sm="8" :xs="24" >
        <el-form-item label="Nome" prop="name">
          <el-input v-model="driver.name"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Cognome" prop="surname">
          <el-input v-model="driver.surname"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Data di nascita" prop="date_birth">
          <el-date-picker
            v-model="driver.date_birth"
            :disabled="type === 0"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="6" :xs="24">
        <el-form-item label="Indirizzo" prop="address">
          <el-input v-model="driver.address" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="3" :xs="10">
        <el-form-item label="N. civico" prop="street_number" >
          <el-input v-model="driver.street_number" :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="Città" prop="city">
          <el-input v-model="driver.city" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="CAP" prop="cap">
          <el-input v-model="driver.cap" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="4" :xs="24">
        <el-form-item label="Provincia" prop="area">
          <el-input v-model="driver.area" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Mail" prop="mail">
          <el-input v-model="driver.mail" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Numero di telefono" prop="telephone_number">
          <el-input v-model="driver.telephone_number" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Ragione sociale">
          <el-input v-model="driver.business_name" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="8" :xs="24">
        <el-form-item label="P.iva">
          <el-input v-model="driver.piva" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="SDI">
          <el-input v-model="driver.sdi" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="pec">
          <el-input v-model="driver.pec" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="12" :xs="24">
        <el-form-item label="IBAN" prop="iban">
          <el-input v-model="driver.iban" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item label="Selezionare tipo di cambio" prop="transmission">
          <el-cascader
            v-model="driver.transmission"
            :options="options"
            :disabled="type === 0"
            :props="props1"
            clearable
            @change="handleChange"
          />
        </el-form-item>
      </el-col>

      <el-col :sm="12" :xs="24">
        <el-form-item label="Selezionare area" prop="areas">
          <el-cascader
          v-model="driver.areas[0]"
            :options="areasId"
            :disabled="type === 0"
            :props="props1"
            clearable
            @change="handleChange_area"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row>
      <el-col :sm="6" :offset="10">
        <el-form-item>
           <router-link class="routerLink" to="/">
          <el-button style="margin: 5px " @click="annulla"> Annulla </el-button>
        </router-link>

           <el-button style="margin: 5px" @click="onSubmit('form')"> Create </el-button>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { reactive, toRaw, ref } from "vue";
import detailsDriverAPI from "../../services/detailsDriverAPI";
import areaAPI from "../../services/driversAPI.js"
import { ElMessage} from "element-plus";
const dayjs = require("dayjs");

export default {
  setup() {
    const props1 = {
      //checkStrictly: true,
       expandTrigger: 'hover'
    };

    const transmission = ref("");

    const options = [
      { value: "manual", label: "manuale" },
      { value: "automatic", label: "automatico" },
      { value: "both", label: "entrambi" }
    ];

    let areasId= ref([]);
    const areas = ref([]);

    const handleChange = (val) => {

      transmission.value = val;
    };

    //const datebirth=new Date()

    const driver = reactive({
      name: "",
      surname: "",
      date_birth:new Date(),
      mail: "",
      address: "",
      street_number:"",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "driver",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      iban: "",
      transmission: transmission.value,
      payed: false,
      isAlive: true,
      areas:[]
    });

    const loadArea = async () => {
      try {
        const response = await areaAPI.getAreas();
        response.data.forEach((area) => {
          let tableRow={
            value: area.id,
            label:`${area.area}, ${area.id}`
          }
          areasId.value.push(tableRow);
          areas.value.push(area);
        });
      } catch(error) {
        console.log(error);
      }
    };

    const handleChange_area= async(val) =>{
      const area= areas.value.filter(elem => elem.id==val)[0];
      console.log(area)
      driver.areas.push(area)
      console.log(driver.areas)
    }

    let formRule = reactive({
      name: [
        { required: true, message: 'Inserire il nome', trigger: 'blur' },
      ],
        surname:  [
        { required: true, message: 'Inserire il cognome', trigger: 'blur' },
      ],
        date_birth:  [
        { type: 'date', required: true, message: 'Inserire data di nascita', trigger: 'blur' }
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        address: [
        { required: true, message: 'Inserire indirizzo', trigger: 'blur' },
      ],
      street_number: [
        { required: true, message: 'Inserire N.civico', trigger: 'blur' },
      ],
        city:  [
        { required: true, message: 'Inserire città', trigger: 'blur' },
      ],
        cap:  [
        { required: true, message: 'Inserire cap', trigger: 'blur' },
      ],
        area:  [
        { required: true, message: 'Inserire provincia', trigger: 'blur' },
      ],
        telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        CF:  [
        { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
        { min: 16, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
      iban:[
          { required: true, message: 'Inserire IBAN', trigger: 'blur' },
          { min: 27, max: 27, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
      transmission:[
         { required: true, message: 'Selezionare tipo di cambio', trigger: 'blur' },
      ],
      areas:[
         { required: true, message: 'Selezionare area', trigger: 'blur' },
      ]
   });

    // Funzioni per aggiungere nuovo
    const addDriver = async (driver) => {
      let response = {};
      try {
        driver.date_birth= dayjs(driver.date_birth).format("YYYY-MM-DD HH:mm:ss")
        driver.name=driver.name.charAt(0).toUpperCase()+driver.name.slice(1).toLowerCase();
        driver.surname=driver.surname.charAt(0).toUpperCase()+driver.surname.slice(1).toLowerCase();
        response = await detailsDriverAPI.addDriver(driver);
        return response;
      } catch {
         ElMessage({
                message: "Error!",
                type: "error",
              });
        console.log("error");
      }
    };
loadArea();

    return {
      formRule,
      areasId,
      areas,
      handleChange_area,
      handleChange,
      addDriver,
      driver,
      props1,
      options,
      transmission,
    };
  },
  methods:{
     onSubmit(formName) {
       this.$refs[formName].validate((valid) => {

        if (valid) 
       {  this.addDriver(toRaw(this.driver)).then((response) => {
        this.$router.push('/');
            if (response.status === 200) {
              ElMessage({
                message: "Driver aggiunto!",
                type: "success",
              });
            } else if(response.status === 400) {
               this.$router.push('/addDriver');
              ElMessage({
                message: "Error!",
                type: "error",
              });
            }
          })
        }else {
          console.log("erroree");}
            })       
       }
  }
};
</script>

<style scope>
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
