<template>
    <h1>Dettagli</h1>
  
    <el-form :model="booking">
      <el-row :gutter="50" type="flex">
        <el-col :sm="8" :xs="24">
          <el-form-item label="Booking Id">
            <el-input v-model="booking.id" readonly="readonly" />
          </el-form-item>
        </el-col>
  
        <el-col :sm="8" :xs="24">
          <el-form-item label="Nome">
            <el-input v-model="booking.name" readonly="readonly" />
          </el-form-item>
        </el-col>
  
        <el-col :sm="8" :xs="24">
          <el-form-item label="Cognome">
            <el-input v-model="booking.surname" readonly="readonly" />
          </el-form-item>
        </el-col>
      </el-row>
  
      <el-row :gutter="50">
          <!--<el-col :sm="6">
        <el-form-item label="Scadenza">
            <el-date-picker
              v-model="booking.dateBooking"
              type="date"
              placeholder="Pick a date"
              format="DD/MM/YYYY"
              style="width: 100%" readonly="readonly"
            />
          </el-form-item>
        </el-col>-->
        <el-col :sm="6">
          <el-form-item label="Imponibile">
            <el-input v-model="booking.imponibile" readonly="readonly"/>
          </el-form-item>
        </el-col>
        <el-col :sm="8" :xs="9">
          <el-form-item label="TOT">
            <el-input v-model="booking.tot_fattura" readonly="readonly"/>
          </el-form-item>
        </el-col>
      </el-row>
  
      <el-row :gutter="15">
        <el-col :sm="8">
          <el-form-item label="Rif fattura">
            <el-input v-model="booking.rif_fattura" />
          </el-form-item>
        </el-col>
        
        <el-col :sm="3" :offset="1" :xs="24">
          <el-form-item label="Esito">
            <el-switch v-model="booking.esito" />
          </el-form-item>
        </el-col>
        <el-col :sm="8">
          <el-form-item label="Data">
            <el-date-picker
              v-model="booking.data"
              type="date"
              format="DD/MM/YYYY"
              placeholder="Pick a date"
              style="width: 100%"
            />
          </el-form-item>
        </el-col>
      </el-row>
  
      <el-row>
        <el-col :sm="3" :offset="15">
          <el-form-item>
          <router-link class="routerLink" to="/">
            <el-button type="primary" @click="update"> Salva </el-button>
            </router-link>
            <router-link :to="{ path: `/contabilita` }" class="routerLink">
              <el-button> Annulla </el-button>
            </router-link>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </template>
  
  <script>
  import { ref, toRaw } from "vue";
  import { useRoute } from "vue-router";
  import { reactive } from "vue";
  import contabilitaAPI from "../../../services/contabilitaAPI";
  import { ElMessage } from "element-plus";
  const dayjs = require("dayjs");
  
  export default {
    setup() {
      const route = useRoute();
      let details = ref({});
      const booking = reactive({
        id: "",
        name: "",
        surname: "",
        imponibile: "",
        tot_fattura: "",
        rif_fattura: "",
        esito: "",
        data: "",
      });

      const booking_update = reactive({
        tot_fattura: "",
        rif_fattura: "",
        esito: "",
        data: "",
      });
  
      const loadDetails = async (id) => {
        try {
          const now= new Date();
          const response = await contabilitaAPI.getBookingHeadquarter_cod4(id);
          response.data.forEach((bookings) => {
  
            if (bookings.id === route.params.booking_id) {
              booking.id = bookings.id;
              booking.name = bookings.customer.name;
              booking.surname = bookings.customer.surname;
              booking.dateBooking = bookings.dateBooking;
              booking.imponibile = bookings.imponibile;
              booking.tot_fattura = bookings.tot_fattura;
              booking.rif_fattura = bookings.rif_fattura;
              booking.esito = bookings.esito;
              booking.data = dayjs(now).format('DD/MM/YY');

            }
          });
  
          return booking;
        } catch (e) {
          console.log(e);
        }
      };
  
      loadDetails(route.params.headquarter_id).then((x) => {
        details.value = x[0];
      });
  
      const update = () => {
        

        updBooking(toRaw(booking)).then((response) => {
          if (response.status === 200) {
            ElMessage({
              message: "Campo aggiornato con successo!",
              type: "success",
            });
            console.log("Campo aggiornato!");
          } else {
            ElMessage.error("errore inatteso");
            console.log("errore inatteso");
          }
        });
      };
  
      const updBooking = async (booking) => {
        let response = {};
        try {

          const now= new Date();
          booking_update.tot_fattura = booking.tot_fattura;
          booking_update.rif_fattura = booking.rif_fattura;
          booking_update.esito = true;
          booking_update.data = dayjs(now).format('YYYY-MM-DD');
      
          response = await contabilitaAPI.patchBooking(booking.id, booking_update);
          console.log(response)

          return response;
        } catch(e) {
          console.log(e);
        }
      };
  
      return {
        booking,
        update,
      };
    },
  };
  </script>
  
  <style>
  .el-col {
    margin-top: 2%;
  }
  </style>
  