import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core'
//use storage pinia
export const isLoggedStore = defineStore('logged', () => {
  /*const isLogged = ref(true);
  const nome = ref('');
  const tipo = ref('');
  const user = ref('');*/

  const viewMenu = useStorage('viewMenu', true);
  const isLogged = useStorage('isLogged', false);
  const jwt= useStorage('jwt','');
  const role= useStorage('role','');

  return { viewMenu, isLogged, jwt, role };
  //const jwt
  //return { isLogged, nome, tipo, user };
});
