<template>
    <h1>COD4</h1>
  

    <h2>Fatture da inserire</h2> 

  <el-row :gutter="20"> 
    <el-col :span="20"   >
      <el-button style= "margin-left:110%; margin-top:-10%;" type="primary" :plain="true" @click="downloadPDF"> scarica PDF </el-button> 
    </el-col>
  </el-row> 

    <el-input v-model="input" placeholder="Inserisci il riferimento fattura">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="fattureTableFalse" style="width: 100%">
      <el-table-column prop="customer_name" label="Nome" width="150" />
      <el-table-column prop="customer_surname" label="Cognome" width="150"  />
      <el-table-column prop="targa" label="Targa" width="100"  />
      <el-table-column prop="date_booking" label="Data booking" width="115" />
      <el-table-column prop="minuti" label="Percorso" width="100" />
      <el-table-column prop="imponibile" label="Imponibile" width="110" />
      <el-table-column prop="tot_fattura" label="Tot fattura" width="110" />
      <el-table-column prop="rif_fattura" label="Rif fattura" width="100" /> 
      <el-table-column prop="esito" label="Esito" width="60" />
      <el-table-column prop="payed_in_app" label="Pagato" width="80"  />
      <el-table-column label="Select" width="85">
      <template #default="scope">
        <el-checkbox v-model="scope.row.selected" @change="handleRowSelect(scope.row)"></el-checkbox>
      </template>
    </el-table-column>
      <el-table-column label="Dettagli">
          <template #default="scope">
            <router-link
              :to="{ path: `/contabilita/add_booking_pay_false/` + headquarterID + `/${scope.row.id}` }"
              class="routerLink"
            >
              <el-button size="small" @click="handleClick(scope.row.id)">
                Modifica
              </el-button>
            </router-link>
          </template>
        </el-table-column>
    </el-table>
  
    <h2>Fatture inserite</h2>
    <el-input v-model="input" placeholder="Inserisci il riferimento fattura">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>
    <el-table :data="fattureTableTrue" style="width: 100%">
      <el-table-column prop="customer_name" label="Nome" width="200" />
      <el-table-column prop="customer_surname" label="Cognome" width="200"  />
      <el-table-column prop="targa" label="Targa" width="150"  />
      <el-table-column prop="date_booking" label="Data booking" width="150" />
      <el-table-column prop="minuti" label="Percorso" width="100" />
      <el-table-column prop="imponibile" label="Imponibile" width="110" />
      <el-table-column prop="tot_fattura" label="Tot fattura" width="110" />
      <el-table-column prop="rif_fattura" label="Rif fattura" width="100" />
      <el-table-column prop="esito" label="Esito" width="60" />
      <el-table-column prop="payed_in_app" label="Pagato" width="80"  />
    </el-table>
  </template>
  
  <script>
  import { ref, computed } from "vue";
  import contabilitaAPI from "../../../services/contabilitaAPI";
  import { useRoute } from "vue-router";
  import jsPDF from "jspdf";
  import { ElMessage } from "element-plus";
  import "jspdf-autotable";
  const dayjs = require("dayjs");
  
  export default {
    setup() {
      const route = useRoute();
      const headquarterID = route.params.id;
      const input = ref("");
      const tableDataTrue = ref([]);
      const tableDataFalse = ref([]);

      const selectedRow = ref([]);

      const handleRowSelect = (row) =>{
        if(row.selected){
          selectedRow.value.push(row);
        }
        console.log(selectedRow.value)
      }

      const downloadPDF = ()=>{
        //const selectedRows = fattureTableFalse.filter((row) => row.selected);
        if (selectedRow.value.length === 0) {
          ElMessage.error("Seleziona almeno una riga!");
          return;
        }
        // Call a function to generate a PDF with the selected rows
        generatePDF(selectedRow.value);
      }

      const generatePDF = (selectedRows)=> {
        const pdf = new jsPDF();
        const header = ["Targa","Nome", "Cognome", "Data booking","Percorso", "Imponibile", "Totale", "Pagato in app"];
        const data = selectedRows.map((row) => [
          row.targa,
          row.customer_name,
          row.customer_surname,
          row.date_booking,
          row.minuti,
          row.imponibile,
          row.tot_fattura,
          row.payed_in_app
        ]);

        pdf.autoTable({
          head: [header],
          body: data,
        });

        const today= new Date();

        // Save the PDF or open it in a new tab
        pdf.save("dettaglio "+ dayjs(today).format("DD-MM-YY")+".pdf");
    }

      const loadMinute = async () => {
        try {
          const response = await contabilitaAPI.getBookingHeadquarter_cod4(route.params.id);

          response.data.forEach((booking) => {
            console.log(booking)
            let tableRow = {
              customer_id: booking.customer.id,
              customer_name: booking.customer.name,
              customer_surname: booking.customer.surname,
              targa: booking.car.targa,
              payed_in_app: booking.tasks[0].pay == true ?"sì":"no",
              id: booking.id,
              date_booking: booking.datePartner_gone != null ? ((booking.datePartner_gone).split('T')[0]) : null,
              minuti: booking.simba_minuteGone +booking.simba_minuteReturn ,
              imponibile: booking.imponibile + ' €',
              tot_fattura: booking.tot_fattura + ' €',
              rif_fattura: booking.rif_fattura,
              esito: booking.esito,
              data: booking.data ? ((booking.data).split('T')[0]) : null,
            };

            if (tableRow.esito) {
              tableDataTrue.value.push(tableRow);
            } else {

              tableDataFalse.value.push(tableRow);
            }
          });
        } catch(e) {
          console.log(e);
        }
      };
  
      loadMinute();
  
      const fattureTableTrue = computed(function () {
        return tableDataTrue.value.filter((row) =>
          row.rif_fattura.toString().toLowerCase().includes(input.value.toLowerCase())
        );
      });
  
      const fattureTableFalse = computed(function () {
        return tableDataFalse.value.filter((row) =>
          row.rif_fattura.toString().toLowerCase().includes(input.value.toLowerCase())
        );
      });
  
      return {
        downloadPDF,
        loadMinute,
        handleRowSelect,
        fattureTableTrue,
        fattureTableFalse,
        input,
        headquarterID
      };
    },
  };
  </script>
  
  <style></style>
  