<template>
  <h1>Nuovo Dipendente</h1>
  <el-form  ref="form" :model="employee"  :rules="formRule" >
    <el-row :gutter="50">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Nome"  prop="name">
          <el-input v-model="employee.name" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Cognome" prop="surname">
          <el-input v-model="employee.surname" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="Data di nascita" prop="date_birth">
          <!-- <el-input v-model="employee.date_birth" :value="employee.date_birth" /> -->
          <el-date-picker
            v-model="employee.date_birth"
            :disabled="type === 0"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>


    <el-row :gutter="50">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Mail" prop="mail">
          <el-input v-model="employee.mail" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Numero di telefono" prop="telephone_number" >
          <el-input v-model="employee.telephone_number" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="12" :xs="24">
        <el-form-item label="Selezionare il ruolo"  prop="role">
          <el-cascader
            v-model="employee.role"
            :disabled="type === 0"
            :options="options"
            :props="props1"
            clearable
            @change="handleChange"
          />
        </el-form-item>
      </el-col>
    </el-row>

     <router-link class="routerLink" to="/partner">
          <el-button style="margin: 5px " @click="annulla"> Annulla </el-button>
        </router-link>
      
       <!-- <router-link v-if="valid_employe===true" class="routerLink" to="/" >
          <el-button style="margin: 5px" @click="onSubmit('form')"> Create </el-button>
            </router-link>-->
        <el-button style="margin: 5px"  type="success" plain @click="onSubmit('form')"> Aggiungi dipendente </el-button>
 
  </el-form>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive,toRaw,ref } from "vue";
import partnerAPI from "../../services/partnerAPI.js";
import { useRoute } from "vue-router";
import { ElMessage } from 'element-plus';

export default {
  setup() {
    const route = useRoute();

    const options = [
      { value: "employee", label: "dipendente" },
      { value: "salesvendor", label: "venditore" }
    ];

    const props1 = {
      //checkStrictly: true,
       expandTrigger: 'hover'
    };

    const role_val = ref("");


    const employee = reactive({
      name: "",
      surname: "",
      date_birth: "",
      mail: "",
      address: "",
      street_number:"",
      city: "",
      area:"",
      cap: "",
      telephone_number: "",
      role: role_val.value,
      headquarters:[]
    });

let valid_employe=ref(false);

    let formRule = reactive({
      name: [
        { required: true, message: 'Inserire il nome', trigger: 'blur' },
      ],
        surname:  [
        { required: true, message: 'Inserire il cognome', trigger: 'blur' },
      ],
        date_birth:  [
        { type: 'date', required: true, message: 'Inserire data di nascita', trigger: 'blur' }
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        role:  [
        { required: true, message: 'Selezionare ruolo', trigger: 'blur' },
      ],

 
  });

    
    const handleChange = (val) => {
      console.log(val);
      employee.role=val[0]
      console.log((role_val.value));
    };

    const loadHeadquarter= async () => {
      try {
        const response = await partnerAPI.getHeadquarter(route.params.id);
        console.log(response.data);

        employee.headquarters.push(response.data);

        console.log(employee.headquarters)
         } catch (error){
            ElMessage({
            message: "Error!",
            type: "error",
          });
        console.log(error);
      }
      }
    /*const annulla = () => {
      router.push(`/details/${proprietarioId}`);
    };*/

    // Funzioni per aggiungere nuovo
    const addemployee = async (employee) => {
      let response = {};
    
      try {
        await loadHeadquarter();
        console.log(employee);
        response = await partnerAPI.postEmployee(employee);
        console.log(response);
        return response;
      } catch(error) {
          ElMessage({
            message: "Error!",
            type: "error",
          });
        console.log(error);
      }
    };

    return {
      handleChange,
      role_val,
      props1,
      options,
      loadHeadquarter,
      addemployee,
      employee,
      valid_employe,
      formRule,
    };
  },

  methods:{
     onSubmit(formName) {
       this.$refs[formName].validate((valid) => {

        if (valid) 
       {   this.addemployee(toRaw(this.employee)).then((response) => {
        if (response.status === 200) {
          this.$router.push('/');
          //this.$router.push({name:'home', params: {id: '[paramdata]'}});
          ElMessage({
            message: "Employee aggiunto con successo!",
            type: "success",
          });
          console.log("employee aggiunto!");
        } else {
            ElMessage({
            message: "Errore!",
            type: "error",
          });
          console.log("errore inatteso");
        }
      });
       }
        else {

          console.log("erroree");}
            })       
       }
  }
};
</script>

<style>
.el-col {
  margin-top: 4%;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
