<template>
  <div
    style="
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-item: center;
    "
  >
    <div
      style="
        width: 400px;
        height: 210px;
        border: 1px solid #409eff;
        border-radius: 20px;
        padding: 20px;
      "
    >
      <h1>Accedi</h1>
      <el-form :model="loginData" label-width="100px">
        <el-form-item label="Nome utente">
          <el-input v-model="loginData.mail" />
        </el-form-item>
        <el-form-item label="Password">
          <el-input
            v-model="loginData.password"
            type="password"
            placeholder="Inserire password"
            show-password
          />
        </el-form-item>

        <el-form-item style="text-align: center; margin-left: 0px">
          <el-button type="primary" @click="onSubmit"> Accedi </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { reactive,toRaw } from 'vue';
import { isLoggedStore } from '@/store/logged';
import authAPI from '../services/authAPI.js';
import { ElMessage } from 'element-plus';

export default {
  setup() {
    //Inizializzo variabili di utility e schema
    const router = useRouter();
    const store = isLoggedStore();
    const loginData = reactive({
      mail: '',
      password: '',
    });

    const logIn_data = async (loginData) => {
      /* ... */
      console.log(loginData)
      try{
         const response = await authAPI.login(loginData);
          console.log(response)
          console.log(response.data.access_token)
  
         return response;
      }catch(error){
          console.log(error);
      }
    };

    //Inizializzo i pulsanti
    const onSubmit = () => {
         logIn_data(toRaw( loginData) ).then((response) => {
        if (response.status === 200 && response.data.role=="admin") {
          store.id=response.data.id;
          store.jwt=response.data.access_token;
          store.role = response.data.role;
          store.isLogged = true;
          console.log(response.data.role)
          /*store.nome = "carlo";
          store.tipo = "admin";
          store.user = "admin";*/
          router.push('/');
        }else if(response.data.role!=="admin" || response.status === 401)  {
           ElMessage({
                message: "Non sei autorizzato ",
                type: "Error",
              });
          console.log('Non sei autorizzato');
        }
        else if (response.status === 404) {
           ElMessage({
                message: "Username o password errati",
                type: "Error",
              });
          console.log('errore inatteso');
        }
      });
    };

 
 

    return {
      loginData,
      onSubmit,
      store
    };
  },
};
</script>
