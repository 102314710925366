<template>
  <h1>Compensi</h1>

  <h2>Driver da liquidare </h2>
  <el-input v-model="input" placeholder="Inserisci nome del driver">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="remTable_nonliquidati"  height="500">
    <el-table-column prop="id" label="ID" width="200">
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="dal" label="Dal" width="100"/>
    <el-table-column prop="al" label="Al" width="100"/>
    <el-table-column prop="importo" label="importo" width="100"/>

    <el-table-column prop="driver_id" label="ID driver" width="150">
      <template #default="scope">
        <el-input :value="scope.row.driver_id"></el-input>
      </template>
    </el-table-column>
    <el-table-column prop="driver_name" label="nome driver" width="200"/>
    <el-table-column label="liquidato">
      <template #default="scope">
        <el-button size="small" @click="liquidato(scope.row.id)">
          Liquidato
        </el-button>
      </template>
    </el-table-column>
    <el-table-column label="Dettagli">
      <template #default="scope">
        <router-link
          :to="{ path: '/contabilita/remuneration/' + `${scope.row.driver_id}` }"
          class="routerLink"
        >
          <el-button size="small" @click="handleClick(scope.row.driver_id)">
            Visualizza
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>

  <h2>Driver liquidati</h2>
  <el-input v-model="input" placeholder="Inserisci nome del driver">
    <template #append>
      <el-button>
        <el-icon><search /></el-icon>
      </el-button>
    </template>
  </el-input>
  <el-table :data="remTable_liquidati" height="500" style="width: 100%">
    <el-table-column prop="id" label="ID" >
      <template #default="scope">
        <el-input :value="scope.row.id"></el-input>
      </template>
      </el-table-column>
    <el-table-column prop="dal" label="Dal" width="100"/>
    <el-table-column prop="al" label="Al" width="100"/>
    <el-table-column prop="importo" label="importo" width="100"/>
    <el-table-column prop="liquidato" label="liquidato" />
    <el-table-column prop="data" label="Data" width="100"/>
    <el-table-column prop="driver_id" label="ID driver" width="200">
      <template #default="scope">
        <el-input :value="scope.row.driver_id"></el-input>
      </template>
      </el-table-column>
    <el-table-column prop="driver_name" label="nome driver" width="200"/>
    <el-table-column label="Dettagli">
      <template #default="scope">
        <router-link
          :to="{ path: '/contabilita/remuneration/' + `${scope.row.driver_id}` }"
          class="routerLink"
        >
          <el-button size="small" @click="handleClick(scope.row.driver_id)">
            Visualizza
          </el-button>
        </router-link>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref, computed } from "vue";
import driversAPI from "../../../services/driversAPI";
import liquidatoAPI from '../../../services/contabilitaAPI.js'

const dayjs = require('dayjs');
export default {
  setup() {

    const input = ref("");
    const tableData_nonLiquidati = ref([]);
    const tableData_liquidati = ref([]);
    const loadRemuneration = async () => {
      try {
        const response = await driversAPI.getRemuneration_liquidati();

        response.data.forEach((remuneration) => {

          if(remuneration.liquidato == false)
          {
            let tableRow = {
            id: remuneration.id,
            dal: dayjs(remuneration.date_start).format('YYYY-MM-DD'),
            al: dayjs(remuneration.date_end).format('YYYY-MM-DD'),
            importo: (remuneration.total_minute) + "€",
            data:  dayjs(remuneration.data).format('YYYY-MM-DD') != null ? dayjs(remuneration.data).format('YYYY-MM-DD'): null,
            driver_id: remuneration.driver.id,
            driver_name: (remuneration.driver.name + " " + remuneration.driver.surname).trim(),
          };

          tableData_nonLiquidati.value.push(tableRow);
        }
        else if(remuneration.liquidato == true)
          {let tableRow = {
            id: remuneration.id,
            dal: dayjs(remuneration.date_start).format('YYYY-MM-DD'),
            al: dayjs(remuneration.date_end).format('YYYY-MM-DD'),
            importo: (remuneration.total_minute) + "€",
            liquidato: remuneration.liquidato,
            data:  dayjs(remuneration.data).format('YYYY-MM-DD'),
            driver_id: remuneration.driver.id,
            driver_name: (remuneration.driver.name + " " + remuneration.driver.surname).trim(),
          };
          tableData_liquidati.value.push(tableRow);
        }

      });

      } catch (error){
        console.log(error);
      }
    };

  const liquidato= async (id)=>{
    const obj={
      data: new Date(),
      liquidato:true};
    await liquidatoAPI.change_to_liquidato(id, obj);
  }
    loadRemuneration();

    const remTable_liquidati = computed(function () {
      return tableData_liquidati.value.filter((row) =>
        row.driver_name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const remTable_nonliquidati = computed(function () {
      return tableData_nonLiquidati.value.filter((row) =>
        row.driver_name.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return{
      liquidato,
        loadRemuneration,
        remTable_liquidati,
        remTable_nonliquidati,
        input
    }
  },
};
</script>

<style></style>
