<template>
  <h1>Nuovo Booking</h1>
  <el-form :model="booking">
    <el-row>
      <el-col>
        <el-form-item label="Data e ora dell'appuntamento">
          <el-date-picker
            v-model="booking.dateBooking"
            type="datetime"
            format="DD/MM/YYYY"
            placeholder="Seleziona data e ora"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <el-form-item label="Consegna dell'auto al partner">
          <el-date-picker
            v-model="booking.datePartner_gone"
            type="datetime"
            format="DD/MM/YYYY"
            placeholder="Seleziona data e ora"/>
        </el-form-item>
      </el-col>
      <el-col :span="2">
        <span class="text-gray-500">-</span>
      </el-col>
      <el-col :span="11">
        <el-form-item label="Ritiro dell'auto dal partner">
          <el-date-picker
            v-model="booking.datePartner_return"
            type="datetime"
            format="DD/MM/YYYY"
            placeholder="Seleziona data e ora" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider />
    <div class="m-4">
      <p>Selezionare un customer</p>
      <el-text class="driver" type="info">Customer</el-text>
      <el-cascader :options="customersId" :props="props1" clearable @change="handleChange_customer"/>
      <el-text class="driver" type="info">Auto</el-text>
      <el-cascader :options="carsId" :props="props1" clearable @change="handleChange_Car"/>
    </div>

    <div class="m-4">
      <p>Seleziona i driver</p>
      <el-text class="driver" type="info">Andata</el-text>
      <el-cascader :options="driversId" :props="props1" clearable @change="handleChange_driverGone"/>
      <el-text class="driver" type="info">Ritorno</el-text>
      <el-cascader :options="driversId" :props="props1" clearable @change="handleChange_driverReturn"/>
    </div>

    <div class="m-4">
      <p>Selezionare dettagli partner</p>
      <el-text class="driver" type="info">Partner</el-text>
      <el-cascader :options="partnersId" :props="props1" clearable @change="handleChange_partner"/>
      <el-text class="driver" type="info">Sede</el-text>
      <el-cascader :options="headquartersId" :props="props1" clearable @change="handleChange_headquarter"/>
      <el-text class="driver" type="info">Dipendente</el-text>
      <el-cascader :options="employeesId" :props="props1" clearable @change="handleChange_employee"/>
    </div>
    <el-divider />
    <p>Punti di presa e riconsegna</p>
    <div 
      v-for="(point, counter_point) in booking.points" v-bind:key="counter_point"
      class="point">
      <el-row :gutter="20">
        <el-col :span="5" style="margin: 0 0.5rem" >
          <el-form-item>
            <el-input v-model="point.address" label="Indirizzo" placeholder="Indirizzo" />
          </el-form-item>
        </el-col>
        <el-col :span="3" style="margin: 0 0.5rem">
          <el-form-item>
            <el-input v-model="point.street_number"  label="N. civico" placeholder="N. civico" />
          </el-form-item>
        </el-col>
        <el-col :span="3" style="margin: 0 0.5rem">
          <el-form-item>
            <el-input v-model="point.cap" label="CAP" placeholder="CAP"/>
          </el-form-item>
        </el-col>
        <el-col :span="5" style="margin: 0 0.5rem">
          <el-form-item>
            <el-input v-model="point.city" label="Città" placeholder="Città"/>
          </el-form-item>
        </el-col>
        <el-col :span="3" style="margin: 0 0.5rem" >
          <el-form-item>
            <el-input v-model="point.province"  label="Provincia" placeholder="Provincia" />
          </el-form-item>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-divider />
      <h2>Tasks</h2>
      <div
        v-for="(task, counter_task) in booking.tasks"
        v-bind:key="counter_task"
      >
        <el-form-item label="Descrizione">
          <el-input v-model="task.description" />
        </el-form-item>
         <el-col :span="5">
            <el-form-item label="Costo">
              <el-input v-model="task.cost" />
            </el-form-item>
          </el-col> 
        <el-form-item label="Il cliente paga i lavori sull'app">
          <el-switch v-model="task.pay" />
        </el-form-item>
      
        <el-button
          type="danger"
          :icon="Delete"
          @click="rimuoviTask($event, counter_task)" circle/>
      </div>
      <el-button style="margin-top: 15px" @click="aggiungiTask" type="success" plain>Aggiungi task</el-button>    
    </div>

    <el-divider />
    <el-form-item label="L'auto del cliente ha un'RCA per guida sopra i 26 anni">
      <el-switch v-model="booking.over_twentysix" />
    </el-form-item>
    <el-form-item label="Il cliente dovrà pagare simba all'interno della sua app">
      <el-switch v-model="booking.simba_pay" />
    </el-form-item>
    <router-link class="routerLink" to="/">
      <el-button type="primary" :plain="true" @click="onSubmit"> Create </el-button>
    </router-link>
    <router-link class="routerLink" to="/driver">
      <el-button @click="annulla"> Annulla </el-button>
    </router-link>
  </el-form>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, ref, toRaw } from 'vue';
//import { reactive, toRaw,ref } from 'vue';
import bookingAPI from '../../services/bookingAPI';
import { ElMessage } from 'element-plus';
import customerAPI from '../../services/customerAPI.js';
import driversAPI from "../../services/driversAPI";
import partnersAPI from '../../services/partnerAPI.js';
import carsAPI from '../../services/carAPI.js'
import { Delete } from '@element-plus/icons-vue';

export default {
  setup() {

const booking =  reactive({
      dateBooking: new Date(),
      datePartner_gone:  new Date(),
      datePartner_return: new Date(),
      customer:{},
      car: {},
      driverGone:{},
      driverReturn: {},
      partner: {},
      headquarter:{},
      employee:{},
      status:'before',
      over_twentysix:true,
      simba_pay:true,
      imponibile:0,
      tot_fattura:0,
      rif_fattura:0,
      esito:false,
      data:"2023-03-20 08:00:00",
      points:[{
        order:0,
        address: "",
        street_number:"",
        cap: "",
        city: "",
        province:"",
        pick_point:true,
        trip:null
      },{
        order:0,
        address: "",
        street_number:"",
        cap: "",
        city: "",
        province:"",
        pick_point:false,
        trip:null
  }],
      tasks:[{description:"", cost:0, pay:false, path:"string"}]
    });

    const tasks=[];

    const date_booking = ref(new Date());
    const datePartner_gone = ref(new Date());
    const datePartner_return = ref(new Date());

    const customersId= ref([]);
    const customers = ref([]);

    const driversId = ref([]);
    const drivers = ref([]);

    const partnersId= ref([]);
    const partners = ref([]);

    let carsId= ref([]);
    const cars = ref([]);

    let headquartersId= ref([]);
    const headquarters = ref([]);

    let employeesId= ref([]);
    const employees = ref([]);

    const customerId={
      customer_id: ""
    }

    //dayjs(now).format('YYYY-MM-DD'),
const customersOptions={};

    const props1 = {
    //checkStrictly: true,
     expandTrigger: 'hover'
    }
    
  const loadCustomers = async () => {
      try {
        const response = await customerAPI.getCustomers();
        response.data.forEach((customer) => {
          let tableRow={
            value: customer.id,
            label: `${customer.name} ${customer.surname}, ${customer.id}`
          }
          customers.value.push(customer);
          customersId.value.push(tableRow);
        });
      } catch {
        console.log('error');
      }
  };

  const loadCars = async (customer_id) => {
    try {
      const response = await carsAPI.getCars(customer_id);
      response.data.forEach((car) => {
        let tableRow={
          value: car.id,
          label: `${car.marca} ${car.modello} ${car.targa}, ${car.id}`
        }
        carsId.value.push(tableRow);
        cars.value.push(car);
      });
    } catch {
      console.log('error');
    }
  };

  const loadDrivers = async () => {
    try {
      const response = await driversAPI.getDrivers();
      response.data.forEach((driver) => {
        let tableRow={
          value: driver.id,
          label: ` ${driver.name} ${driver.surname}, ${driver.id}`
        }
        driversId.value.push(tableRow);
        drivers.value.push(driver);
      });
    } catch {
      console.log('error');
    }
  };

  const loadPartners = async () => {
    try {
      const response = await partnersAPI.getPartners();
      response.data.forEach((partner) => {
        let tableRow={
          value: partner.id,
          label:`${partner.company_name}, ${partner.id}`
        }
        partnersId.value.push(tableRow);
        partners.value.push(partner);
      });
    } catch {
      console.log('error');
    }
  };

  const loadHeadquarters = async (partnerId) => {
    try {
      const response = await partnersAPI.getHeadquarters_byPartner(partnerId);

      response.data.forEach((headquarter) => {
       let tableRow={
          value: headquarter.id,
          label: `${headquarter.address} ${headquarter.street_number} ${headquarter.city}, ${headquarter.id}` 
        }
        headquartersId.value.push(tableRow);
        headquarters.value.push(headquarter);
      });
    } catch(e) {
      console.log(e);
    }
  };

  const loadEmployees = async (headquarterId) => {
    try {
      const response = await partnersAPI.getEmployees_byHeadquarter(headquarterId);
      response.data.forEach((employee) => {
       let tableRow={
          value: employee.id,
          label:`${employee.name} ${employee.surname},${employee.id}`
        }
        employeesId.value.push(tableRow);
        employees.value.push(employee);
        });
       } catch {
      console.log('error');
    }
  };


  const handleChange_customer=async (val)=>{
    carsId.value=([]);
    booking.customer = customers.value.filter(customer => customer.id==val)[0];
    customerId.customer_id=val[0];
    loadCars(customerId);
  };     

  const handleChange_Car=async (val)=>{
    booking.car = cars.value.filter(car => car.id == val)[0];
  };

  const handleChange_driverGone=async (val)=>{
     booking.driverGone =  drivers.value.filter( driver => driver.id == val)[0];
  };

  const handleChange_driverReturn=async (val)=>{
    booking.driverReturn =  drivers.value.filter( driver => driver.id == val)[0];
  };

  const handleChange_partner=async (val)=>{
    headquartersId.value=([]);
    booking.partner = partners.value.filter( partner => partner.id == val)[0];
    loadHeadquarters(val[0]);
  };
    
  const handleChange_headquarter=async (val)=>{
    employeesId.value=([])
    booking.headquarter = headquarters.value.filter(headquarter => headquarter.id == val)[0];
    loadEmployees(val[0]);
  };

  const handleChange_employee=async (val)=>{
    booking.employee = employees.value.filter( employee => employee.id == val)[0];
  };

  const rimuoviTask = (e, counter) => {
      booking.tasks.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiTask = (e) => {
      booking.tasks.push({

        description: '',
        cost: 0,
        pay: true,
        path:"string"
      });
      e.preventDefault();
    };
  
    const addBookingNew = async (booking) => {
      let response = {};
      try {
        //console.log(booking)
        response = await bookingAPI.addBooking(booking);
        console.log(response)
        return response;
      } catch (error){
        console.log(error);
      }
    };
    
    const onSubmit = () => {
      console.log("*****booking******")
      console.log(toRaw(booking))
      
     addBookingNew(toRaw(booking)).then((response) => {
        if (response.status === 200) {
          ElMessage({
                message: "Booking aggiunto!",
                type: "success",
              });
          console.log('Booking aggiunto!');
        } else {
              ElMessage({
                message: "Errore!",
                type: "error",
              });
          console.log('errore inatteso');
        }
      });
    };

  loadCustomers();  
  loadDrivers();
  loadPartners();

    return {
      tasks,
      customersOptions,
      loadCars,
      addBookingNew,
      customerId,
      customers,
      customersId,
      employees,
      employeesId,
      booking,
      drivers,
      driversId,
      partners,
      partnersId,
      carsId,
      headquartersId,
      headquarters,
      onSubmit,
      props1,
      date_booking,
      datePartner_return,
      datePartner_gone,
      handleChange_driverGone,
      handleChange_driverReturn,
      handleChange_Car,
      handleChange_customer,
      handleChange_partner,
      handleChange_headquarter,
      handleChange_employee,
      aggiungiTask,
      rimuoviTask,
      Delete
    };
  },
};
</script>

<style scope >

.driver{
  font-size: 14px;
  padding: 0px 20px ;
}

.point{
  margin-bottom: 5px;
}

</style>
