<template>
  <h1>Dettagli del cliente {{ customer.name }} {{ customer.surname }}</h1>

  <el-form :model="customer">
    <el-row :gutter="50" type="flex">
      <el-col :sm="7" :xs="24">
        <el-form-item label="Id">
          <el-input v-model="customer.id" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="5" :xs="24">
        <el-form-item label="Nome">
          <el-input v-model="customer.name" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Cognome">
          <el-input v-model="customer.surname" />
        </el-form-item>
      </el-col>

     <el-col :sm="5">
        <el-form-item label="Data di nascita">
          <!-- <el-input v-model="customer.date_birth" :value="customer.date_birth" /> -->
          <el-date-picker
            v-model="customer.date_birth"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
     
      <el-col :sm="7">
        <el-form-item label="Indirizzo">
          <el-input v-model="customer.address" />
        </el-form-item>
      </el-col>

      <el-col :sm="4" :xs="10">
        <el-form-item label="N. civico" >
          <el-input v-model="customer.street_number"/>
        </el-form-item>
      </el-col>

      <el-col :sm="5">
        <el-form-item label="Città">
          <el-input v-model="customer.city" />
        </el-form-item>
      </el-col>
      <el-col :sm="4">
        <el-form-item label="CAP">
          <el-input v-model="customer.cap" />
        </el-form-item>
      </el-col>
      <el-col :sm="4">
        <el-form-item label="Provincia">
          <el-input v-model="customer.area" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Mail">
          <el-input v-model="customer.mail" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="Numero di telefono">
          <el-input
            v-model="customer.telephone_number"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Ragione sociale">
          <el-input v-model="customer.business_name" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="P.iva">
          <el-input v-model="customer.piva" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="SDI">
          <el-input v-model="customer.sdi" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="pec">
          <el-input v-model="customer.pec" />
        </el-form-item>
      </el-col>
    </el-row>

    
    <el-space wrap>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <h3>scegli sede </h3>
            <el-text class="driver" type="info">Partner</el-text>
            <el-cascader :options="partnersId" :props="props1" clearable @change="handleChange_partner"/>
           <!-- <el-text class="driver" type="info">Sede</el-text>
            <el-cascader :options="headquartersId" :props="props1" clearable @change="handleChange_headquarter"/>-->
          </div>
        </template>

        <h4> modifica minuti </h4>
        <div v-for="min in minutes" :key="min.id"> 
          <el-row :gutter="50" type="flex">
            <el-col :sm="8" :xs="24">
              <el-form-item label="minuti">
                <el-input v-model="min.minute" />
              </el-form-item>
            </el-col>

            <el-col :sm="8" :xs="24">
              <el-form-item label="minuti residui">
                <el-input v-model="min.residual_minute" />
              </el-form-item>
            </el-col>

            <el-col :sm="8" :xs="24">
              <el-form-item label="scadenza minuti">
                <el-date-picker v-model="min.expiration_minute"  type="date" format="DD/MM/YYYY" placeholder="Pick a date" style="width: 100%"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-button  type="primary" :plain="true" @click="update_minute"> Salva </el-button>

        <el-divider/>
        <h4> aggiungi minuti </h4>
        <div
          v-for="(new_minute, counter_min) in new_minutes"
          v-bind:key="counter_min"
        >
          <el-row :gutter="50">
            <el-col :sm="8" :xs="24">
              <el-form-item label="minuti">
                <el-input v-model="new_minute.minute" />
              </el-form-item>
            </el-col>

            <el-col :sm="8" :xs="24">
              <el-form-item label="minuti residui">
                <el-input v-model="new_minute.residual_minute" />
              </el-form-item>
            </el-col>

            <el-col :sm="8" :xs="24">
              <el-form-item label="scadenza minuti">
                <el-date-picker v-model="new_minute.expiration_minute"  type="date" format="DD/MM/YYYY" placeholder="Pick a date" style="width: 100%"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button
            type="danger"
            :icon="Delete"
            circle
            @click="rimuoviMin($event, counter_min)"/>
          <el-button @click="aggiungiMin" type="success" plain>Aggiungi minuti</el-button>    
        </div>
      <router-link class="routerLink" to="/">
        <el-button style="margin-top: 30px" type="primary" :plain="true" @click="add"> Salva </el-button>
        </router-link>
      </el-card>
    </el-space>

    <el-row>
      <el-col :sm="3" :offset="1" :xs="24">
        <el-form-item label="E' attivo">
          <el-switch v-model="customer.isAlive" />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :offset="9">
        <el-form-item>
        <router-link :to="{ path: `/customer` }" class="routerLink">
          <el-button  type="primary" :plain="true" @click="update"> Salva </el-button>
          </router-link>
          <router-link :to="{ path: `/customer` }" class="routerLink">
            &nbsp;
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { ref, toRaw } from "vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import customerAPI from "../../services/customerAPI";
import { ElMessage } from 'element-plus';
import partnersAPI from '../../services/partnerAPI.js';
import minuteAPI from '../../services/contabilitaAPI.js';
import { Delete } from '@element-plus/icons-vue';
//const dayjs = require('dayjs');
export default {
  setup() {
  const route = useRoute();
  let details = ref({});

  const partnersId= ref([]);
  const partners = ref([]);

  let minutes=ref([]);

  let minute= ({
      id:"",
      minute:0,
      residual_minute:0,
      expiration_minute:"",
      customer:{},
      partner:{}

    })

  const new_minutes= ref([{ }])

  const payload={
        customer_id:"",
        partner_id: ""
      }


  const customer = reactive({
      id: "",
      name: "",
      surname: "",
      date_birth: "",
      mail: "",
      address: "",
      street_number:"",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "customer",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      isAlive: true,
    });


  const loadMinutes= async (payload) => {
    try {
      
      const response = await minuteAPI.getMinuteInfo(payload);
      if(response.data!=null){
        let tableRow={
          id: response.data.id,
          minute: response.data.minute,
          residual_minute: response.data.residual_minute,
          expiration_minute:(response.data.expiration_minute),
          partner:minute.partner,
          customer:{}
        }
        minutes.value.push(tableRow);
     }
    } catch(error) {
      console.log(error);
    }
  };

    const loadPartners = async () => {

      try {
        const response = await partnersAPI.getPartners();
        response.data.forEach((partner) => {
          let tableRow={
            value: partner.id,
            label:`${partner.id}, ${partner.company_name}`
          }
          partnersId.value.push(tableRow);
          partners.value.push(partner);
        });
      } catch(error) {
        console.log(error);
      }
    };

    const handleChange_partner=async (val)=>{
  
      minutes.value = ([]);
      minute.partner = partners.value.filter(partner => partner.id == val)[0];
      payload.partner_id= minute.partner.id;
      payload.customer_id=route.params.id;

      loadMinutes( payload)
    };
    

    const loadDetails = async (id) => {
      try {
        const response = await customerAPI.getCustomer(id);

        console.log(response.data.street_number);
        customer.id = response.data.id;
        customer.name = response.data.name;
        customer.surname = response.data.surname;
        customer.date_birth = response.data.date_birth;
        customer.mail = response.data.mail;
        customer.address = response.data.address;
        customer.city = response.data.city;
        customer.street_number=response.data.street_number;
        customer.cap = response.data.cap;
        customer.area = response.data.area;
        customer.telephone_number = response.data.telephone_number;
        customer.business_name = response.data.business_name;
        customer.piva = response.data.piva;
        customer.sdi = response.data.sdi;
        customer.pec = response.data.pec;
        customer.isAlive = response.data.isAlive;

        //minutes.minute.customer=customer;
        return customer;
      } catch (e) {
        console.log(e);
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x[0];
      
    });

    const update = () => {
      updCustomer(toRaw(customer)).then((response) => {
        if (response.status === 200) {
          if (customer.isAlive == true) {
            ElMessage({
              message: "Customer aggiornato con successo!",
              type: "success",
            });
            console.log("Customer aggiornato con successo!");
          } else {
            ElMessage.error("Customer disattivato!");
            console.log("Customer disattivato!");
          }
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const updCustomer = async (customer) => {
      let response = {};
      try {
        response = await customerAPI.updCustomer(customer);
        return response;
      } catch {
        console.log("error");
      }
    };

    const update_minute = () => {
     try {
        toRaw(minutes.value).forEach(async (minute) => {
          console.log(minute)
          updMinute(minute).then((response) => {
            console.log(response.status)
            if (response.status === 200) {
                
                console.log("Minuti aggiornati con successo!");
            } else {
              console.log("errore inatteso");
            }
          });
      })
      ElMessage({
                message: "Minuti aggiornati con successo!",
                type: "success",
              });

     } catch(error){
      console.log(error)
       ElMessage({
                message: "Errore nell'aggiornamento",
                type: "error",
              });
     }


    };
    const add=()=>{
      new_minutes.value.forEach(async (new_minute)=>{
      addMinute(new_minute).then((response) => {
          if (response.status === 201) {
              ElMessage({
                message: "Minuti aggiunti con successo!",
                type: "success",
              });
              console.log("Minuti aggiunti con successo!");

          } else {
            console.log("errore inatteso");
          }
        });
    })
    }

    const addMinute = async(new_minute)=>{
      let response = {};
      try {
          new_minute.customer= customer;
          new_minute.partner=minute.partner;
          console.log(new_minute)
          response = await customerAPI.addMinute(new_minute);
          console.log(response.data);
          return response;

      } catch(error) {
        console.log(error);
      }
    }

    const updMinute = async (minute) => {    
      let response = {};
      try {
          minute.customer= customer;
          console.log("minutiiiiii")
          console.log(minute)
          response = await customerAPI.updMinute(minute);
          return response;

      } catch(error) {
        console.log(error);
      }
    };

  const rimuoviMin = (e, counter) => {
      new_minutes.value.splice(counter, 1);
      e.preventDefault();
    };

  const aggiungiMin = (e) => {
      new_minutes.value.push({
        minute:0,
        residual_minute:0,
        expiration_minute:"",
        partner:minute.partner,
        customer: {}
      });

      e.preventDefault();
    };

  loadPartners();
    return {
      handleChange_partner,
      payload,
      partners,
      partnersId,
      customer,
      update,
      minutes,
      minute,
      aggiungiMin,
      rimuoviMin,
      add,
      new_minutes,
      update_minute,
      Delete
    };
  },
};
</script>

<style>
.el-col {
  margin-top: 80%;
}
</style>
