import API from './API';

export default {

  getDetails(id) {
    return API().get('/driver/' + id);
  },

  addDriver(driver) {
    return API().post('/register', driver)

  },

  updDriver(driver) {
    return API().patch('/driver/' + driver.id, driver)
  },
  
  getDrivers() {
    return API().get('/driver');
  },

}