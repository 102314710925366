import axios from 'axios';

/*export default (url = 'http://localhost:3000/') =>  {*/

/*export default (url = 'http://44.201.196.104:3000/') => {*/
export default (url = 'https://gestionalesimba.it/') => {
  return axios.create({
    baseURL: url,
    headers: {
    "Access-Control-Allow-Origin": "*", //aggiungere jwt
    "Authorization": "Bearer " + localStorage.getItem("jwt")
  },
  });
};
