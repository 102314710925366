<template>
  <h1>Contabilità</h1>
  <el-row>
    <el-col :sm="sm">
      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update();
            fatture();
          "
          >COD 1</el-button
        >
        &nbsp;
        <el-text class="text" v-if="value1" tag="p" size="small"
          >COD1: SITUAZIONE IN CUI IL PARTNER FA UN ABBONAMENTO A SIMBA PER UTILIZZARE
          QUESTO GESTIONALE <br />(PER ADESSO TOMBOLINI NON PAGA)</el-text
        >
      </el-row>

      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update_partner();
            minuti();
          "
          >COD 2</el-button
        >
        <el-text class="text" v-if="value1_cod2" tag="p" size="small"
          >COD2: SITUAZIONE IN CUI IL PARTNER (addetto vendite) AGGIUNGE UN NUOVO CLIENTE
          E GLI ASSEGNA UN TOT DI MINUTI GRATIS <br />(CHE IL PARTNER PAGHERA' A
          ASIMBA)</el-text
        >
      </el-row>

      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update();
            booking();
          "
          >COD 3</el-button
        >
        <el-text class="text" v-if="value1" tag="p" size="small"
          >COD3: SITUAZIONE IN CUI IL PARTNER IL CHIAMA IL CLIENTE E GLI PROPONE SIMBA.
          QUINDI LUI AGGIUNGERA' UN BOOKING E RICEVE <br />LA PROVVIGIONE CHE GLI E' STATA
          ASSEGNATA. MA IL CLIENTE PAGA DALL'APP</el-text
        >
      </el-row>

      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update();
            booking_pay();
          "
          >COD 4</el-button
        >
        <el-text class="text" v-if="value1" tag="p" size="small"
          >COD4: SITUAZIONE IN CUI IL PARTNER IL CHIAMA IL CLIENTE E GLI PROPONE SIMBA.
          QUINDI LUI AGGIUNGERA' UN BOOKING<br />
          RICEVE COMUNQUE LA PROVVIGLIONE MA IL PARTNER PAGA</el-text>
      </el-row>

      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update();
            booking_plus();
          "
          >COD 5</el-button>
        <el-text class="text" v-if="value1" tag="p" size="small"
        >COD5: L'ELENCO DELLE PROVVIGIONI PER OGNI PARTNER
        </el-text>
      </el-row>

      <el-row class="cod">
        <el-button
          color="#2c3e50"
          @click="
            update();
            task();
          "
          >COD 6</el-button>

        <el-text class="text" v-if="value1" tag="p" size="small"
          >COD6: QUANDO UN PARTNER FA UN NUOVO BOOKING PUO' DECIDERE DI INCASSARE
          DIRETTAMENTE I SOLDI DEL SUO SERVIZIO, <br />SENZA FARLI INCASSARE PRIMA A
          SIMBA.</el-text>
      </el-row>

      <el-row class="cod">
        <router-link class="routerLink" to="/contabilita/remuneration"> 
          <el-button
            color="#2c3e50"
          >COD 7</el-button>
        </router-link>
        <el-text class="text" v-if="value1" tag="p" size="small"> Retribuzione Driver
        </el-text>
      </el-row>
    </el-col>

  
    <el-col v-if="value2" :sm="19" >
      <el-input
        v-model="input"
        placeholder="Inserisci la città dell'heartquarter"
      >
        <template #append>
          <el-button>
            <el-icon><search /></el-icon>
          </el-button>
        </template>
      </el-input>
      <el-table :data="headquarterTable" height="100%" style="width: 100%">
        <el-table-column prop="id" label="id" >
          <template #default="scope">
            <el-input :value="scope.row.id"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="partner" label="partner" />
        <el-table-column prop="province" label="provincia" />
        <el-table-column prop="cap" label="cap" />
        <el-table-column prop="city" label="città" />
        <el-table-column prop="province" label="provincia" />


        <el-table-column label="Dettagli">
          <template #default="scope">
            <router-link
              :to="{ path: '/contabilita' + path_variable + `${scope.row.id}` }"
              class="routerLink"
            >
              <el-button size="small" @click="handleClick(scope.row.id)">
                Visualizza
              </el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  
  
    <el-col v-else-if="value2_cod2" :sm="21" >
      <el-input
        v-model="input"
        placeholder="Inserisci la ragione sociale del partner"
      >
        <template #append>
          <el-button>
            <el-icon><search /></el-icon>
          </el-button>
        </template>
      </el-input>
      <el-table :data="partnerTable" height="100%" style="width: 90%">
        <el-table-column prop="id" label="id" :span="5">
          <template #default="scope">
            <el-input :value="scope.row.id"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="partner" label="ragione sociale" :span="5"/>

        <el-table-column label="Dettagli" :span="5">
          <template #default="scope">
            <router-link
              :to="{ path: '/contabilita' + path_variable + `${scope.row.id}` }"
              class="routerLink"
            >
              <el-button size="small" @click="handleClick(scope.row.id)">
                Visualizza
              </el-button>
            </router-link>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import { ref, computed } from "vue";
import partnerAPI from "../../services/partnerAPI";

export default {
  setup() {
    //codice per popolare la tabella degli Headquarter del COD1
    const input = ref("");
    const tableData = ref([]);

    const tableData_partner= ref([]);

    const loadHeadquarter = async () => {
      try {
        const response = await partnerAPI.getHeadquarters();
        console.log(response.data);
        response.data.forEach((headquarter) => {
  
          let tableRow = {
            id: headquarter.id,
            province: headquarter.province,
            cap: headquarter.cap,
            city: headquarter.city,
            address: headquarter.address,
            partner: headquarter.partner.company_name
          };
          
          tableData.value.push(tableRow);
        });
      } catch {
        console.log("error");
      }
    };

     const loadPartner = async () => {
      try {
        const response = await partnerAPI.getPartners();
  
        response.data.forEach((partner) => {

          let tableRow = {
            id: partner.id,
            partner: partner.company_name
          };
          tableData_partner.value.push(tableRow);
        });
        console.log(tableData_partner.value);
      } catch {
        console.log("error");
      }
    };

    loadHeadquarter();
    loadPartner();

    const headquarterTable = computed(function () {
      return tableData.value.filter((row) =>
        row.city.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    const partnerTable = computed(function () {
      return tableData_partner.value.filter((row) =>
        row.partner.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      input,
      tableData,
      tableData_partner,
      partnerTable,
      headquarterTable,
    };
  },

  data() {
    const sm = ref(0);
    const path_variable = ref("");
    const value1 = ref(true);
    const value1_cod2 = ref(true);
    const value2 = ref(false);
    const value2_cod2= ref(false);
    sm.value = 24;



    const update = () => {
      if (value1.value && !value2.value) {
        //scompare la scritta
        value1.value = false;
        value1_cod2.value = false;

        //compare tabella
        value2.value = true;
        value2_cod2.value = false;
        sm.value = 3;
      } else {

        //compare scritta
        value1.value = true;
        value1_cod2.value = true;

         //scompare tabella
        value2.value = false;
        value2_cod2.value = false;
        sm.value = 24;
      }

      return {
        value1,
        value2,
      };
    };

    const update_partner = () => {
      if (value1_cod2.value && !value2_cod2.value) {
         //scompare la scritta
        value1_cod2.value = false;
        value1.value = false;

        //compare tabella
        value2_cod2.value = true;
        value2.value = false;
        sm.value = 3;
      } else {

        //compare scritta
        value1_cod2.value = true;
         value1.value = true;

          //scompare tabella
        value2_cod2.value = false;
        value2.value = false;
        sm.value = 24;
      }

      return {
        value2_cod2,
        value1_cod2
      };
    };

    const fatture = () => {
      path_variable.value = "/fatture/";
    };

    const minuti = () => {
      path_variable.value = "/minute/";
    };

    const booking = () => {
      path_variable.value = "/booking_cost_positive/";
    };

    const booking_pay = () => {
      path_variable.value = "/booking_pay_false/";
    };

    const booking_plus = () => {
      path_variable.value = "/booking_plus/";
    };

    const task = () => {
      path_variable.value = "/task/";
    };

    return {
      update,
      update_partner,
      fatture,
      minuti,
      booking,
      booking_pay,
      booking_plus,
      task,
      value1,
      value1_cod2,
      value2_cod2,
      value2,
      path_variable,
      sm,
    };
  },
};
</script>
<style scope>
.cod {
  margin-bottom: 30px;
  color: #5499c7;
}

.text {
  margin-left: 30px;
  text-align: center;
  color: #5499c7;
}
</style>
