<template>
  <h1>Dettagli del driver {{ driver.name }} {{ driver.surname }}</h1>

  <el-form :model="driver">
    <el-row :gutter="50" type="flex">
      <el-col :sm="5" :xs="17">
        <el-form-item label="Id">
          <el-input v-model="driver.id" readonly="readonly" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Nome">
          <el-input v-model="driver.name" />
        </el-form-item>
      </el-col>

      <el-col :sm="6" :xs="24">
        <el-form-item label="Cognome">
          <el-input v-model="driver.surname" />
        </el-form-item>
      </el-col>

      <el-col :sm="7">
        <el-form-item label="Data di nascita">
          <!-- <el-input v-model="driver.date_birth" /> -->
          <el-date-picker
            v-model="driver.date_birth"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
        </el-row>

      <el-row :gutter="15">  
        <el-col :sm="7">
        <el-form-item label="Indirizzo">
          <el-input v-model="driver.address" />
        </el-form-item>
      </el-col>
      <el-col :sm="4">
        <el-form-item label="Città">
          <el-input v-model="driver.city" />
        </el-form-item>
      </el-col>
      <el-col :sm="4">
        <el-form-item label="N.civico">
          <el-input v-model="driver.street_number" />
        </el-form-item>
      </el-col>
      <el-col :sm="4" :xs="9">
        <el-form-item label="CAP">
          <el-input v-model="driver.cap" />
        </el-form-item>
      </el-col>
      <el-col :sm="4" :xs="15">
        <el-form-item label="Provincia">
          <el-input v-model="driver.area" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
          <el-col :sm="6" :xs="24">
        <el-form-item label="CF">
          <el-input v-model="driver.CF" />
        </el-form-item>
      </el-col>
      <el-col :sm="9">
        <el-form-item label="Mail">
          <el-input v-model="driver.mail" />
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="Numero di telefono">
          <el-input v-model="driver.telephone_number" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Ragione sociale">
          <el-input v-model="driver.business_name" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="P.iva">
          <el-input v-model="driver.piva" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="SDI">
          <el-input v-model="driver.sdi" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="pec">
          <el-input v-model="driver.pec" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="10">
        <el-form-item label="Tipo di cambio">
          <el-input v-model="driver.transmission" />
        </el-form-item>
      </el-col>
      <el-col :sm="14">
        <el-form-item label="IBAN">
          <el-input v-model="driver.iban" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-col :sm="3" :offset="9">
    <router-link class="routerLink" to="/">
      <el-button type="primary" :plain="true" @click="update"> Salva </el-button>
      </router-link>
      <router-link :to="{ path: `/driver` }" class="routerLink">
        <el-button> Annulla </el-button>
      </router-link>
    </el-col>

  </el-form>
</template>

<script>
import { ref, toRaw } from "vue";
import { useRoute } from "vue-router";
import { reactive } from "vue";
import detailsDriverAPI from "../../services/detailsDriverAPI";
import { ElMessage } from "element-plus";

export default {
  setup() {
    const route = useRoute();
    let details = ref({});
    const driver = reactive({
      id: "",
      CF:"",
      name: "",
      surname: "",
      date_birth: "",
      mail: "",
      address: "",
      street_number:"",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "driver",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      iban: "",
      transmission: "",
      payed: false,
      isAlive: true,
    });

    const loadDetails = async (id) => {
      try {
        const response = await detailsDriverAPI.getDetails(id);

        driver.id = response.data.id;
        driver.CF = response.data.CF;
        driver.name = response.data.name;
        driver.surname = response.data.surname;
        driver.date_birth = response.data.date_birth;
        driver.mail = response.data.mail;
        driver.address = response.data.address;
        driver.street_number=response.data.street_number;
        driver.city = response.data.city;
        driver.cap = response.data.cap;
        driver.area = response.data.area;
        driver.telephone_number = response.data.telephone_number;
        driver.business_name = response.data.business_name;
        driver.piva = response.data.piva;
        driver.sdi = response.data.sdi;
        driver.pec = response.data.pec;
        driver.iban = response.data.iban;
        driver.transmission = response.data.transmission;
        driver.payed = response.data.payed;
        driver.isAlive = response.data.isAlive;

        return driver;
      } catch (e) {
        console.log(e);
      }
    };

    loadDetails(route.params.id).then((x) => {
      details.value = x[0];
    });

    const update = () => {
      updDriver(toRaw(driver)).then((response) => {
        if (response.status === 200) {
          ElMessage({
            message: "Driver aggiornato con successo!",
            type: "success",
          });
          console.log("Driver aggiornato!");
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const updDriver = async (driver) => {
      let response = {};
      try {
        response = await detailsDriverAPI.updDriver(driver);
        console.log(response);
        return response;
      } catch {
        console.log("error");
      }
    };

    return {
      driver,
      update,
    };
  },
};
</script>

<style>
.el-col {
  margin-top: 2%;
}
</style>
