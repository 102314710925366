<template>
  <h1>Nuovo partner</h1>

  <el-form ref="form" :model="partner" :rules="formRule" >
    <el-row>
      <el-col :span="9">
        <el-form-item label="Ragione sociale" prop="company_name">
          <el-input v-model="partner.company_name" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form-item label="P.iva" prop="piva">
          <el-input v-model="partner.piva" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item label="CF" prop="CF">
          <el-input v-model="partner.CF" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row el-row :gutter="21">
      <el-col :span="7">
        <el-form-item label="pec">
          <el-input v-model="partner.PEC" />
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="email" prop="mail">
          <el-input v-model="partner.mail" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="telefono" prop="phone_number">
          <el-input v-model="partner.phone_number" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="10">
        <el-form-item label="SDI">
          <el-input v-model="partner.SDI" />
        </el-form-item>
      </el-col>
      <el-col :span="10">
        <el-form-item label="IBAN" prop="IBAN">
          <el-input v-model="partner.IBAN" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="21">
      <el-col :span="7">
        <el-form-item label="CAB">
          <el-input v-model="partner.CAB" />
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="ABI">
          <el-input v-model="partner.ABI" />
        </el-form-item>
      </el-col>
      <el-col :span="7">
        <el-form-item label="RID" prop="RID">
          <el-input v-model="partner.RID" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <el-form-item label="provvigione" prop="provvigione">
          <el-input v-model="partner.provvigione" />
        </el-form-item>
      </el-col>
    <el-col :span="5">
        <el-form-item label="tariffa simba" prop="tariffa_simba">
          <el-input v-model="partner.tariffa_simba" :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider />
    <h2>Sedi</h2>
    <div
      v-for="(headquarter_elem, counter_headquarter) in partner.headquarters"
      v-bind:key="counter_headquarter">
      <el-form ref="form" :model="headquarter_elem" :rules="formRule_sedi">
        <el-row :gutter="20">
          <el-col :span="5" style="margin: 0 0.5rem" >
            <el-form-item label="indirizzo" prop="address">
              <el-input v-model="headquarter_elem.address"  :disabled="type === 0" />
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin: 0 0.5rem">
            <el-form-item label="N. civico" prop="street_number">
              <el-input v-model="headquarter_elem.street_number" :disabled="type === 0"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin: 0 0.5rem">
            <el-form-item label="CAP" prop="cap">
              <el-input v-model="headquarter_elem.cap" :disabled="type === 0"/>
            </el-form-item>
          </el-col>
          <el-col :span="5" style="margin: 0 0.5rem">
            <el-form-item label="Città" prop="city">
              <el-input v-model="headquarter_elem.city"  :disabled="type === 0"/>
            </el-form-item>
          </el-col>
          <el-col :span="3" style="margin: 0 0.5rem" >
            <el-form-item label="Provincia" prop="province">
              <el-input v-model="headquarter_elem.province"  :disabled="type === 0"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-button
          type="danger"
          :icon="Delete"
          @click="rimuoviHeadquarter($event, counter_headquarter)" circle/>
        <el-button @click="aggiungiHeadquarter" type="success" plain>Aggiungi sede</el-button>    
      </el-form>
    </div>
    <el-divider />

    <router-link class="routerLink" to="/">
      <el-button style="margin: 5px "  @click="annulla"> Annulla </el-button>
    </router-link>
    <el-button style="margin: 5px " type="primary" @click="onSubmit('form')"> Create </el-button>


  </el-form>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw } from 'vue';
import { ElMessage } from 'element-plus';
import partnerAPI from '../../services/partnerAPI';
import { Delete } from '@element-plus/icons-vue';

export default {
  setup() {

    const partner =  reactive({
      company_name: "",
      piva:  "",
      mail: "",
      CF: "",
      SDI:"",
      PEC:"",
      phone_number:"",
      IBAN:"",
      CAB:"",
      ABI:"",
      RID:"",
      provvigione:0,
      tariffa_simba:0,
      headquarters:[
        { address:"",
          street_number:"",
          cap:"", 
          city:"",
          province:"",  
       }]
    });

    const headquarters=[];
    
    let formRule_sedi=reactive({ 
  
      address:[
        { required: true, message: 'Inserire via', trigger: 'blur' }
      ], 
      street_number:[
        { required: true, message: 'Inserire numero civico', trigger: 'blur' }
      ], 
        cap:[
        { required: true, message: 'Inserire CAP', trigger: 'blur' }
      ], 
      city:[
        { required: true, message: 'Inserire città', trigger: 'blur' }
      ], 
        province: [
        { required: true, message: 'Inserire provincia', trigger: 'blur' }
      ], 
  });


     let formRule = reactive({
      company_name: [
        { required: true, message: 'Inserire ragione sociale', trigger: 'blur' },
      ],
        piva:  [
        { required: true, message: 'Inserire piva', trigger: 'blur' },
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        CF:  [
        { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
        { min: 11, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
      /*RID:  [
        { required: true, message: 'Inserire RID', trigger: 'blur' }
      ],
      provvigione:  [
        { required: true, message: 'Inserire RID', trigger: 'blur' }
      ],*/
      tariffa_simba:  [
        { required: true, message: 'Inserire tariffa simba', trigger: 'blur' }
      ],
      province: [
        { required: true, message: 'Inserire provincia', trigger: 'blur' }
      ], 
      cap:[
        { required: true, message: 'Inserire CAP', trigger: 'blur' }
      ], 
      city:[
        { required: true, message: 'Inserire città', trigger: 'blur' }
      ], 
      address:[
        { required: true, message: 'Inserire via', trigger: 'blur' }
      ], 
      street_number:[
        { required: true, message: 'Inserire numero civico', trigger: 'blur' }
      ], 
  });


    const rimuoviHeadquarter = (e, counter) => {
      partner.headquarters.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiHeadquarter = (e) => {
     partner.headquarters.push({
        province:"", 
        cap:"", 
        city:"",
        address:"",
        street_number:""
      });
      e.preventDefault();
    };
  
    const addPartnerNew = async (partner) => {
      let response = {};
      try {
        console.log(partner)
        response = await partnerAPI.postPartner(partner);
        console.log(response)
        return response;
      } catch (error){
            ElMessage({
            message: "Error!",
            type: "error",
          });
        console.log(error);
      }
    };
    
   /* const onSubmit = () => {
      console.log("*****partner******")
      console.log(toRaw(partner))
      
     addPartnerNew(toRaw(partner)).then((response) => {
        if (response.status === 201) {
          ElMessage({
            message: "Partner aggiunto con successo!",
            type: "success",
          });
          console.log('partner aggiunto!');
          //router.push(`/details/${proprietarioId}`);
        } else {
          ElMessage({
            message: "Error!",
            type: "error",
          });
          console.log('errore inatteso');
        }
      });
    };*/
    return {
      partner,
      headquarters,
     // onSubmit,
      addPartnerNew,
      aggiungiHeadquarter,
      rimuoviHeadquarter,
      Delete,
      formRule,
      formRule_sedi
    };
  },
  methods:{
     onSubmit(formName) {
       this.$refs[formName].validate((valid) => {

        if (valid) 
       {   this.addPartnerNew(toRaw(this.partner)).then((response) => {
            if (response.status === 201) {
              this.$router.push('/');
              ElMessage({
                message: "Partner aggiunto con successo!",
                type: "success",
              });
              console.log('partner aggiunto!');
            } else {
              ElMessage({
                message: "Error!",
                type: "error",
              });
              console.log('errore inatteso');
            }
        });
        }else {
          this.$router.push('/addPartner');
          console.log("erroree");}
            })       
       }
  }
};
</script>

<style scope >

.driver{
  font-size: 14px;
  padding: 0px 20px ;
}

.point{
  margin-bottom: 5px;
}

</style>
