<template>
 <h1>Sedi</h1>
  <div class="mt-4">
    <el-input v-model="input" placeholder="Inserisci indirizzo">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>

    <el-table :data="headquarterTable" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id" width="100">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="partner_point" label="indirizzo" width="270" />
      <el-table-column
        prop="employees"
        class-name="sub_row"
        label="dipendenti di ogni sede">
        <template #default="scope">
          <el-table :data="scope.row.employees" :show-header="false">
            <el-table-column prop="id" label="id" width="100">
              <template #default="scope">
                <el-input :value="scope.row.id"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="nome" width="120"/>
            <el-table-column prop="surname" label="cognome" width="100"/>
            <el-table-column prop="role" label="role" width="100"/>
            <el-table-column prop="mail" label="mail" width="300"/>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column width="50" >
        <template #default="scope">
          <router-link :to="{ path: `/employee/${scope.row.id}` }" class="routerLink">
            <el-button size="small" @click="handleClick(scope.row.id)">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-divider />
    <h2>Aggiungi una nuova sede</h2>
    <!-- <div
      v-for="(headquarter, counter_headquarter) in new_headquarter"
      v-bind:key="counter_headquarter"
    > -->
    <el-row :gutter="20">
      <el-col :span="5" style="margin: 0 0.5rem" >
        <el-form-item>
          <el-input v-model="new_headquarter.address" label="Indirizzo" placeholder="Indirizzo" />
        </el-form-item>
      </el-col>
      <el-col :span="3" style="margin: 0 0.5rem">
        <el-form-item>
          <el-input v-model="new_headquarter.street_number"  label="N. civico" placeholder="N. civico" />
        </el-form-item>
      </el-col>
      <el-col :span="3" style="margin: 0 0.5rem">
        <el-form-item>
          <el-input v-model="new_headquarter.cap" label="CAP" placeholder="CAP"/>
        </el-form-item>
      </el-col>
      <el-col :span="5" style="margin: 0 0.5rem">
        <el-form-item>
          <el-input v-model="new_headquarter.city" label="Città" placeholder="Città"/>
        </el-form-item>
      </el-col>
      <el-col :span="3" style="margin: 0 0.5rem" >
        <el-form-item>
          <el-input v-model="new_headquarter.province"  label="Provincia" placeholder="Provincia" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-button @click="onSubmit" type="success" plain>Aggiungi sede</el-button>    
  </div>
 <!-- </div>-->
</template>

<script>
import { reactive, ref, computed,toRaw } from "vue";
import partnerAPI from "../../services/partnerAPI.js";
import { useRoute } from "vue-router";
import { ElMessage } from 'element-plus';

export default {
  setup() {
    const route = useRoute();
    const input = ref("");
    const tableData = ref([]);
    //let partner={};
    
    const new_headquarter=reactive({
        province:"", 
        cap:"", 
        city:"",
        address:"",
        street_number:"",
        partner:{}
    })

    const loadPartner= async () => {
      try {
        const response = await partnerAPI.get_specificPartner(route.params.id);
        //partner=response.data;

        new_headquarter.partner= response.data;
        
        //console.log(partner)
         } catch (error){
        console.log(error);
      }
      }

    const loadHeadquarter = async () => {
      try {
        const response = await partnerAPI.getHeadquarters_byPartner(route.params.id);

        response.data.forEach((headquarter) => {
              let tableRow = {
                id:headquarter.id,
                partner_point:(
                    headquarter.address +
                    ' ' +
                    headquarter.street_number +
                    ' ' +
                    headquarter.city +
                    ' ' +
                    headquarter.cap +
                    ' ' +
                    headquarter.province
                ).trim(),
                employees:headquarter.employees
              };
              tableData.value.push(tableRow);
            })
      } catch (error){
        console.log(error);
      }
    };

    const addHeadquarterNew = async (headquarter) => {
      let response = {};
      try {
        console.log(headquarter)
        response = await partnerAPI.postHeadquarter(headquarter);
        return response;
      } catch (error){
        console.log(error);
      }
    };

 const onSubmit = () => {
      console.log("*****partner******")
      console.log(toRaw(new_headquarter))
      
     addHeadquarterNew(toRaw(new_headquarter)).then((response) => {
        if (response.status === 201) {
ElMessage({
            message: "Sede aggiunto con successo!",
            type: "success",
          });
          console.log('headquarter aggiunto!');
          //router.push(`/details/${proprietarioId}`);
        } else {
          console.log('errore inatteso');
        }
      });
    };
    
    loadHeadquarter();
    loadPartner();

    const headquarterTable = computed(function () {
      return tableData.value.filter((row) =>
        row.partner_point.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
        onSubmit,
       new_headquarter,
      loadHeadquarter,
      headquarterTable,
      input
    };
  },
};
</script>

<style></style>
