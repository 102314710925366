<template>
  <h1>Nuova fattura</h1>

  <el-form :model="fattura">
    <el-row :gutter="50">
      <el-col :sm="11" :xs="24">
        <el-form-item label="Data di inizio">
          <el-date-picker
            v-model="fattura.date_start"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
      <el-col :sm="13" :xs="24">
        <el-form-item label="Data di fine">
          <el-date-picker
            v-model="fattura.date_end"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item class="row" label="Descrizione">
      <el-input v-model="fattura.description" />
    </el-form-item>

    <el-row :gutter="50" class="row">
      <el-col :sm="8" :xs="24">
        <el-form-item label="Imponibile">
          <el-input v-model="fattura.imponibile" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Importo">
          <el-input v-model="fattura.importo" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Rif fattura">
          <el-input v-model="fattura.rif_fattura" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row class="row">
      <el-col :sm="3" :xs="9">
        <el-form-item label="Esito">
          <el-switch v-model="fattura.esito" />
        </el-form-item>
      </el-col>
      <el-col :sm="13" :xs="24">
        <el-form-item label="Data">
          <el-date-picker
            v-model="fattura.data"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :offset="20">
        <el-form-item>
          <router-link class="routerLink" to="/">
            <el-button type="primary" @click="onSubmit()"> Create </el-button>
          </router-link>
          &nbsp;
          <router-link class="routerLink" :to="{path: '/contabilita/fatture/' + id}">
            <el-button> Annulla </el-button>
          </router-link>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import { reactive, toRaw} from "vue";
import { ElMessage } from "element-plus";
import contabilitaAPI from "@/services/contabilitaAPI";
import { useRoute } from "vue-router";


export default {
  setup() {
    
    const route = useRoute();
    const id = route.params.id;
    const fattura = reactive({
      date_start: "",
      date_end: "",
      description: "",
      imponibile: "",
      importo: "",
      rif_fattura: "",
      esito: true,
      data: "",
    });

    const onSubmit = () => {
      addFattura(toRaw(fattura)).then((response) => {
        if (response.status === 201) {
          ElMessage({
            message: "Fattura aggiunta!",
            type: "success",
          });
          console.log("Fattura aggiunto!");
        } else {
          console.log("errore inatteso");
        }
      });
    };

    const addFattura = async (fattura) => {
      let response = {};
      let headquarter = {};
      try {
        
        headquarter = await contabilitaAPI.getHeadquarter(route.params.id);
        let new_fattura = Object.assign({},fattura, {'headquarter': headquarter.data});
        response = await contabilitaAPI.addFattura(new_fattura);
        
        return response;
      } catch(e) {
        console.log(e);
      }
    };

    return {
      addFattura,
      fattura,
      onSubmit,
      id
    };
  },
};
</script>

<style scope>
.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}
.row {
  margin-top: 3%;
}
</style>
