<template>
 <h1>NUOVO CLIENTE</h1>
  <el-form ref="form" :model="customer"  :rules="formRule" >
    <el-row :gutter="50">
      <el-col :sm="8" :xs="24"  >
        <el-form-item label="Nome" prop="name">
          <el-input v-model="customer.name"  :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="Cognome" prop="surname" >
          <el-input v-model="customer.surname"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="8">
        <el-form-item label="Data di nascita" prop="date_birth" >
          <!-- <el-input v-model="customer.date_birth" :value="customer.date_birth" /> -->
          <el-date-picker
            v-model="customer.date_birth"
            type="date"
            format="DD/MM/YYYY"
            placeholder="Pick a date"
            style="width: 100%"
            :disabled="type === 0"
          />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :sm="6" :xs="24" >
        <el-form-item label="Mail" prop="mail" >
          <el-input v-model="customer.mail"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="Numero di telefono"  prop="telephone_number" >
          <el-input v-model="customer.telephone_number"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="CF" prop="CF" >
          <el-input v-model="customer.CF"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="6" :xs="24">
        <el-form-item label="P.iva">
          <el-input v-model="customer.piva" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="50">
      <el-col :sm="6" :xs="24">
        <el-form-item label="Indirizzo" prop="address" >
          <el-input v-model="customer.address" :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="3" :xs="10">
        <el-form-item label="N. civico" prop="street_number" >
          <el-input v-model="customer.street_number" :disabled="type === 0" />
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="Città" prop="city">
          <el-input v-model="customer.city"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="5" :xs="24">
        <el-form-item label="CAP" prop="cap">
          <el-input v-model="customer.cap"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
      <el-col :sm="4" :xs="24" >
        <el-form-item label="Provincia" prop="area" >
          <el-input v-model="customer.area"  :disabled="type === 0"/>
        </el-form-item>
      </el-col>
    </el-row>

    <el-row :gutter="50">
      <el-col :sm="5" :xs="24">
        <el-form-item label="Ragione sociale">
          <el-input v-model="customer.business_name" />
        </el-form-item>
      </el-col>

      <el-col :sm="5" :xs="24">
        <el-form-item label="SDI">
          <el-input v-model="customer.sdi" />
        </el-form-item>
      </el-col>

      <el-col :sm="5" :xs="24">
        <el-form-item label="pec">
          <el-input v-model="customer.pec" />
        </el-form-item>
      </el-col>
      <el-col :sm="8" :xs="24">
        <el-form-item label="IBAN">
          <el-input v-model="customer.iban" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider />
    <h4>Aggiungere auto al cliente</h4>
    <div
      v-for="(car, counter_car) in customer.cars"
      v-bind:key="counter_car"
    >
      <el-form ref="form" :model="car"  :rules="formRule" >
        <el-row> 
          <el-col :span="8" style="margin-top: 50px">
            <el-form-item label="Intestatario" prop="intestatario">
              <el-input v-model="car.intestatario" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
        </el-row>
        <el-row :gutter="20"> 
          <el-col :span="5">
            <el-form-item label="Marca" prop="marca">
              <el-input v-model="car.marca" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Modello" prop="modello">
              <el-input v-model="car.modello" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Targa" prop="targa">
              <el-input v-model="car.targa" :disabled="type === 0" />
            </el-form-item>
          </el-col> 
          <el-col :span="5">
            <el-form-item label="Telaio">
              <el-input v-model="car.telaio" />
            </el-form-item>
          </el-col> 
          <el-col :sm="12" :xs="24" >
            <el-form-item label="Selezionare tipo di cambio" prop="cambio">
              <el-cascader
                v-model="car.transmission"
                :options="options"
                :props="props1"
                clearable
                :disabled="type === 0" 
                @change="handleChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-button v-if="counter_car != 0"
          style="margin-top: 15px" 
          type="danger"
          :icon="Delete"
          @click="rimuoviCar($event, counter_car)" circle/>

        <el-button style="margin-top: 15px" @click="aggiungiCar" type="success" plain>Aggiungi auto</el-button>    
      </el-form>
    </div>
    <el-divider />

  <el-space wrap>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <h3>scegli sede </h3>
            <el-text type="info">Partner</el-text>
            <el-cascader :options="partnersId" :props="props1" clearable @change="handleChange_partner"/>
           <!-- <el-text type="info">Sede</el-text>
            <el-cascader :options="headquartersId" :props="props1" clearable @change="handleChange_headquarter"/> -->
          </div>
        </template>
        <h4> aggiungi minuti </h4>
        <div>
          <el-row :gutter="50">
            <el-col :sm="8" :xs="24">
              <el-form-item label="minuti">
                <el-input v-model="customer.minutes[0].minute" />
              </el-form-item>
            </el-col>

            <el-col :sm="8" :xs="24">
              <el-form-item label="scadenza minuti">
                <el-date-picker v-model="customer.minutes[0].expiration_minute"  type="date" format="DD/MM/YYYY" placeholder="Pick a date" style="width: 100%"/>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-space>

    <el-col :sm="6" :offset="10" >
      <el-form-item>
        <router-link class="routerLink" to="/">
          <el-button style="margin: 5px " @click="annulla"> Annulla </el-button>
        </router-link>
      
        <el-button style="margin: 5px" @click="onSubmit('form')"> Create </el-button>
      </el-form-item>
    </el-col>
  </el-form>
</template>

<script>
//import { useRoute, useRouter } from 'vue-router';
import { reactive, toRaw, ref } from "vue";
import { ElMessage } from 'element-plus';
import { Delete } from '@element-plus/icons-vue';
import customerAPI from '../../services/customerAPI';
import partnersAPI from '../../services/partnerAPI.js';

export default {
  setup() {
    const props1 = {
      //checkStrictly: true,
       expandTrigger: 'hover'
    };

  const partnersId= ref([]);
  const partners = ref([]);

    let transmission = ref("");

    const options = [
      { value: "manual", label: "manuale" },
      { value: "automatic", label: "automatico" }
    ];
    
    let formRule = reactive({
      name: [
        { required: true, message: 'Inserire il nome', trigger: 'blur' },
      ],
        surname:  [
        { required: true, message: 'Inserire il cognome', trigger: 'blur' },
      ],
        date_birth:  [
        { type: 'date', required: true, message: 'Inserire data di nascita', trigger: 'blur' }
      ],
        mail:  [
        { required: true, message: 'Inserire mail', trigger: 'blur' },
      ],
        address: [
        { required: true, message: 'Inserire indirizzo', trigger: 'blur' },
      ],
      street_number: [
        { required: true, message: 'Inserire N.civico', trigger: 'blur' },
      ],
        city:  [
        { required: true, message: 'Inserire città', trigger: 'blur' },
      ],
        cap:  [
        { required: true, message: 'Inserire cap', trigger: 'blur' },
      ],
        area:  [
        { required: true, message: 'Inserire provincia', trigger: 'blur' },
      ],
        telephone_number:  [
        { required: true, message: 'Inserire numero di telefono', trigger: 'blur' },
      ],
        CF:  [
        { required: true, message: 'Inserire codice fiscale', trigger: 'blur' },
        { min: 16, max: 16, message: 'Numero di cifre errato', trigger: 'blur' },
      ],
      intestatario:[
        {required: true, message: 'Inserire intestatario', trigger: 'blur' },
      ],
      marca:[
        {required: true, message: 'Inserire marca', trigger: 'blur' },
      ], 
      modello:[
        {required: true, message: 'Inserire modello', trigger: 'blur' },
      ], 
      targa:[
        {required: true, message: 'Inserire targa', trigger: 'blur' },
      ], 
      cambio:[
        {required: true, message: 'Scegliere cambio', trigger: 'blur' },
      ], 
  });

    const handleChange = (val) => {
      console.log(val);
      transmission.value = val;
      console.log(transmission.value);
    };

    const customer = reactive({
      name: "",
      surname: "",
      date_birth: "",
      mail: "",
      address: "",
      street_number:"",
      city: "",
      cap: "",
      area: "",
      telephone_number: "",
      role: "customer",
      business_name: "",
      piva: "",
      sdi: "",
      pec: "",
      iban: "",
      device_token:null,
      isAlive: true,
      CF:"",
      cars:[{
      intestatario: "",
      marca:"",
      modello:"",
      telaio:"",
      transmission: transmission.value,
      }],
      minutes:[{
        minute:0,
        residual_minute:0,
        expiration_minute:"",
        data: new Date(),
        accepted:false,
        partner:{}
      }]
    });

    const cars=[];
    
    const loadPartners = async () => {
      try {
        const response = await partnersAPI.getPartners();
        response.data.forEach((partner) => {
          let tableRow={
            value: partner.id,
            label:`${partner.id}, ${partner.company_name}`
          }
          partnersId.value.push(tableRow);
          partners.value.push(partner);
        });
      } catch(error) {
        console.log(error);
      }
    };

    const handleChange_partner=async (val)=>{

      customer.minutes[0].partner = partners.value.filter( partner => partner.id == val)[0];
      console.log( partners.value.filter( partner => partner.id == val)[0])

    };

    // Funzioni per aggiungere nuovo
    const addcustomer_data = async (customer) => {
      let response = {};
      try {

        if(customer.minutes[0].minute===0 && customer.minutes[0].expiration_minute ==="")
        { 
          delete customer.minutes;
          console.log(customer)
          response = await customerAPI.addCustomer(customer);
          return response;}
        else{
          customer.minutes[0].residual_minute=customer.minutes[0].minute;
          response = await customerAPI.addCustomer(customer); 
        }
      } catch(error) {
          ElMessage({
                message: "Error!",
                type: "error",
              });
        console.log("error");
        console.log(error);
      }
    };


    const rimuoviCar = (e, counter) => {
      customer.cars.splice(counter, 1);
      e.preventDefault();
    };
    const aggiungiCar = (e) => {
      customer.cars.push({

        intestatario: "",
        marca:"",
        modello:"",
        telaio:"",
        transmission:transmission.value,
      
      });
      e.preventDefault();
    };
    
    loadPartners();

    return {
      cars,
      addcustomer_data,
      customer,
      props1,
      options,
      handleChange,
      rimuoviCar,
      aggiungiCar,
      transmission,
      Delete,
      partnersId,
      partners,
      handleChange_partner,
      formRule
    };
  },
  
 methods:{
     onSubmit(formName) {
       this.$refs[formName].validate((valid) => {

        if (valid) 
       {  this.addcustomer_data(toRaw(this.customer)).then((response) => {

        if (response.status === 200) { 
           this.$router.push('/');
          ElMessage({
                message: "Cliente aggiunto con successo!",
                type: "success",
              });
        } else if(response.status === 400 && response.data.error=="mail usata"){
           ElMessage({
                message: "Mail usata!",
                type: "error",
              });
          console.log("errore mail");
        }else{
          this.$router.push('/addCustomer');
           ElMessage({
                message: "Errore inatteso!",
                type: "error",
              });
                console.log("errore ");
        }
      });
       }
        else {

          console.log("erroree");}
            })       
       }
  }
};
</script>

<style>
.el-col {
  margin-top: 4%;
}

.demo-date-picker .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 10px;
}
.demo-date-picker .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  flex: 1;
}

.el-alert {
  margin: 120px 100px 100px;
}

.el-alert:first-child {
  margin: 50;
}
</style>
