<template>
  <h1>Partner</h1>
  <div class="mt-4">
    <el-input v-model="input" placeholder="Inserisci nome del partner">
      <template #append>
        <el-button>
          <el-icon><search /></el-icon>
        </el-button>
      </template>
    </el-input>

    <el-table :data="filteredTable" height="500" style="width: 100%" :header-cell-style="{ background: 'rgb(35,44,81)'}">
      <el-table-column prop="id" label="id" width="100">
        <template #default="scope">
          <el-input :value="scope.row.id"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="partner" label="ragione sociale" width="150" />
      <el-table-column prop="mail" label="mail" width="150"/>
      <el-table-column prop="provvigione" label="provvigione" width="110" />
      <el-table-column
        prop="headquarters"
        class-name="sub_row"
        label="sedi di ogni partner" >
        <template #default="scope">
          <el-table :data="scope.row.headquarters" :show-header="false">
            <el-table-column prop="id"  label="id sede" width="110">
              <template #default="scope">
                <el-input :value="scope.row.id"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="address"  label="indirizzo" width="200" />
            <el-table-column prop="street_number"  label="n. civico" width="50" />
            <el-table-column prop="CAP"  label="CAP" width="50" />
            <el-table-column prop="city"  label="città" width="110" />
            <el-table-column prop="province"  label="provincia" width="50" />
          </el-table>
        </template>
      </el-table-column>
      <el-table-column width="50" >
        <template #default="scope">
          <router-link :to="{ path: `/headquarter/${scope.row.id}` }" class="routerLink">
            <el-button size="small" @click="handleClick(scope.row.id)">
              <el-icon><DArrowRight /></el-icon>
            </el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <router-link class="routerLink" to="/addPartner">
      <el-button type="primary" @click="addpartnerClick()" plain>
        Aggiungi partner</el-button>
    </router-link>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import partnersAPI from "../../services/partnerAPI.js";

export default {
  setup() {
    const partners = ref([]);
    const value = ref(0);
    const input = ref("");
    const tableData = ref([]);


  const loadPartners = async () => {
      try {
        const response = await partnersAPI.getHeadquarters_toPartner();

        response.data.forEach((partner) => {
          let tableRow = {
            id: partner.id,
            partner: partner.company_name,
            mail: partner.mail,
            provvigione: partner.provvigione + "%",
            headquarters: partner.headquarters
           /* indirizzo: ( headquarter.address
             + " " + headquarter.street_number
             + ", " + headquarter.city
             + " " + headquarter.cap
             + " " + headquarter.province).trim(),
*/
          };
          tableData.value.push(tableRow);
        });
        console.log(tableData.value);
      } catch(error) {
        console.log(error);
      }
    };

    loadPartners();

    const filteredTable = computed(function () {
      return tableData.value.filter((row) =>
        row.partner.toLowerCase().includes(input.value.toLowerCase())
      );
    });

    return {
      input,
      partners,
      value,
      tableData,
      filteredTable
    };
  },
};
</script>

<style>
/*.mytable tr td{
  border: 1px solid black;
}*/
</style>
